import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { isLoggedIn } from "utils";

export const getactivecourseskyc = async (data) => {
  return decryptData(
    await axios.get(
      `/active-courses-kyc?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const getkycstudents = async (data) => {
  return decryptData(
    await axios.get(
      `/get-kyc-students?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const updatekycflag = async (data) => {
  return decryptData(
    await axios.get(
      `/update-kyc-flag?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const getverifykyclist = async (data) => {
  return decryptData(
    await axios.get(
      `/student-verified-kyc-list?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const getrequestedkyclist = async (data) => {
  return decryptData(
    await axios.get(
      `/student-do-kyc-list-request?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
