import React, { useLayoutEffect, useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Input } from "components/Form";
import { toast } from "react-hot-toast";
import { IconButton } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotFound from "components/not-found/NotFound";
import useFullPageLoader from "hooks/useFullPageLoader";
import { getverifykyclist } from "services/KYC/KYCcontroller"; // Update with correct import path
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";

const VerifiedKYCList = () => {
  const [record, setRecord] = useState([]);
  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const fetchKYCList = async (page = 1, search = "") => {
    try {
      showLoader();
      const res = await getverifykyclist({ page, search });
      hideLoader();

      if (res.code === 200) {
        const { rows } = res.data.students;
        const totalRecords = res.data.students.count;
        setRecord(rows || []);
        setTotal(totalRecords);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      hideLoader();
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const paginationHandler = (page) => {
    setActivePage(page);
    fetchKYCList(page, searchTerm);
  };

  useLayoutEffect(() => {
    fetchKYCList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchKYCList(1, searchTerm);
      setActivePage(1);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return (
    <React.Fragment>
      <title>Shubhra Viraj | Verified KYC List</title>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="search_wrap">
              <div className="SearchBox" style={{ gap: 10 }}>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <h4 className="Title">Verified KYC List</h4>
            </div>
            <div className="TableList overflow-auto table-responsive">
              {loader}
              <Table bordered className="table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Student Name</th>
                    <th>Email ID</th>
                    <th>Mobile</th>
                    <th>Verified BY</th>
                    <th>View Profile</th>
                  </tr>
                </thead>
                <tbody>
                  {record.length > 0 ? (
                    record.map((student, index) => (
                      <tr key={student.UserID}>
                        <td>{(activePage - 1) * 10 + (index + 1)}</td>
                        <td>{`${student.firstName} ${
                          student.middleName ? student.middleName + " " : ""
                        }${student.lastName}`}</td>
                        <td>{student.EmailID}</td>
                        <td>{student.Mobile}</td>
                        <td>{student.kycverifiedby}</td>
                        <td>
                          <Link
                            to={`/kyc-management/student-kyc-action/${
                              student?.Mobile || student?.EmailID
                            }?hideButtons=true`}
                          >
                            <IconButton
                              aria-label="profile"
                              className="px-2 py-0"
                              variant="outlined"
                            >
                              <PersonIcon sx={{ color: "#4971B2" }} />
                            </IconButton>
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NotFound cols={12} />
                  )}
                </tbody>
              </Table>
              {total > 10 && (
                <div className="Pagination">
                  <Pagination
                    activePage={activePage}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    itemsCountPerPage={10}
                    totalItemsCount={total}
                    pageRangeDisplayed={5}
                    onChange={paginationHandler}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VerifiedKYCList;
