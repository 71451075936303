import CryptoJS from "crypto-js";
import toast from "react-hot-toast";

// const config = {
//   mode: CryptoJS.mode.CBC,
//   padding: CryptoJS.pad.Pkcs7,
// };

export const encryptData = (value) => {
  //console.log("enc method called");
  //console.log(JSON.stringify(value));
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(value),
    process.env.REACT_APP_CRYPTO_SECRET_KEY
  ).toString();
  //console.log(data);
  return data;
};

export const decryptData = (response) => {
  if (response.name === "AxiosError") {
    return response;
  } else {
    const { data } = response;
    if (data) {
      const bytes = CryptoJS.AES.decrypt(
        data,
        process.env.REACT_APP_CRYPTO_SECRET_KEY
      );
      const finalValue = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return finalValue;
    } else {
      return response;
    }
  }
};

export const normalEncryptData = (value) => {
  const data = CryptoJS.AES.encrypt(
    value,
    process.env.REACT_APP_CRYPTO_SECRET_KEY
  ).toString();

  return data;
};

export const normalDecryptData = (data) => {
  if (data) {
    const bytes = CryptoJS.AES.decrypt(
      data,
      process.env.REACT_APP_CRYPTO_SECRET_KEY
    );
    const finalValue = bytes.toString(CryptoJS.enc.Utf8);
    return finalValue;
  } else {
    return data;
  }
};

export function disableReactDevTools() {
  // Check if the React Developer Tools global hook exists
  if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ !== "object") {
    return;
  }

  for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
    if (prop === "renderers") {
      // initialize this with an empty `Map`,
      // else it will throw an error in console

      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
    } else {
      // Replace all of its properties with a no-op function or a null value
      // depending on their types

      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] =
        typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === "function"
          ? () => {}
          : null;
    }
  }
}


// __________________________  Helper Responses  _______________________________//
export const failResponse = (res) => {
  if(res.message != "Network Error"){
  toast.error(res.message, { id: "fail" });
  }
};

export const errorResponse = (err) => {
  if(err?.response?.data?.message || err.message != "Network Error"){
  toast.error(err?.response?.data?.message || err.message);
  }
};

export const customMessage = (message) => {
  if(message != "Network Error"){
  toast.error(message);
  }
};
