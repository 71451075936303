import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { isLoggedIn } from "utils";

export const getLoginReport = async (data) => {
  return decryptData(
    await axios.get(
      `/report/login?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const downloadLoginReport = async (data) => {
  return `${
    process.env.REACT_APP_API_URL
  }/admin/report/login?reqData=${encryptData({
    ...data,
    access_token: isLoggedIn(),
  })}`;
};

export const getLectureReport = async (data) => {
  return decryptData(
    await axios.get(
      `/report/lecture?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const downloadLectureReport = async (data) => {
  return `${
    process.env.REACT_APP_API_URL
  }/admin/report/lecture?reqData=${encryptData({
    ...data,
    access_token: isLoggedIn(),
  })}`;
};

export const getTestListByCourse = async (data) => {
  return decryptData(
    await axios.get(
      `/report/course/wise/test?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getTestReport = async (data) => {
  return decryptData(
    await axios.get(
      `/report/test/prelims?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const downloadAttemptTestReport = async (data) => {
  return `${
    process.env.REACT_APP_API_URL
  }/admin/report/test/prelims?reqData=${encryptData({
    ...data,
    access_token: isLoggedIn(),
  })}`;
};

export const getMainsTestReport = async (data) => {
  return decryptData(
    await axios.get(
      `/report/test/mains?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const downloadMainsAttemptTestReport = async (data) => {
  return `${
    process.env.REACT_APP_API_URL
  }/admin/report/test/mains?reqData=${encryptData({
    ...data,
    access_token: isLoggedIn(),
  })}`;
};

export const getExceptionalLectureReport = async (data) => {
  return decryptData(
    await axios.get(
      `/report/exception?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const getAffiliateMacIDs = async (data) => {
  return decryptData(
    await axios.get(
      `/affiliate-macids?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const updateAffiliateMacID = async (data) => {
  return decryptData(
    await axios.get(
      `/reset-macids?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const downloadExceptionalLectureReport = async (data) => {
  return `${
    process.env.REACT_APP_API_URL
  }/admin/report/exception?reqData=${encryptData({
    ...data,
    access_token: isLoggedIn(),
  })}`;
};

export const getGroupMeetingReport = async (data) => {
  return decryptData(
    await axios.get(
      `/report/groupmeeting?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const getLectureAttemptLog = async (data) => {
  return decryptData(
    await axios.get(
      `/report/lecture-attempt-log?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const getPiracyAttemptReport = async (data) => {
  return decryptData(
    await axios.get(
      `/report/piracy-attempt-report?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getKnoxApiErrorList = async (data) => {
  return decryptData(
    await axios.get(
      `/report/knox-api-error-log?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const downloadGroupMeetingReport = async (data) => {
  return `${
    process.env.REACT_APP_API_URL
  }/admin/report/groupmeeting?reqData=${encryptData({
    ...data,
    access_token: isLoggedIn(),
  })}`;
};

export const getAffiliateBillingDetails = async (data = {}) => {
  return decryptData(
    await axios.get(
      `/get-billing-details-by-affiliateid?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getReportCourses = async (data) => {
  return decryptData(
    await axios.get(
      `/get-report-courses?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getDataForSRreport = async (data) => {
  return decryptData(
    await axios.get(
      `/import-data-for-srreport?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getPSIRStudentList = async (data) => {
  return decryptData(
    await axios.get(
      `/get-psir-student-list?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const downloadExcelPSIR = async (data) => {
  return decryptData(
    await axios.get(
      `/get-psir-student-excel-export?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getTestStudentCount = async (data) => {
  return decryptData(
    await axios.get(
      `/get-test-student-count-report?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getTestStudentAttendanceCount = async (data) => {
  return decryptData(
    await axios.get(
      `/get-test-student-attendance-report?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getTestStudentDetails = async (data) => {
  return decryptData(
    await axios.get(
      `/get-test-student-detail-report?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getTestStudentAttendanceDetail = async (data) => {
  return decryptData(
    await axios.get(
      `/get-test-student-attendance-detail-report?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const getAdmissionSummaryReport = async (data) => {
  return decryptData(
    await axios.get(
      `/report/get-active-and-inactive-courses?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const getAdmissionCount = async (data) => {
  return decryptData(
    await axios.get(
      `/report/get-admission-summary-report?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const getAdmissionByAffiliate = async (data) => {
  return decryptData(
    await axios.get(
      `/get-admission-done-by-affiliates?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};