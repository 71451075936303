import React, { useState } from "react";
import { Button } from "components/Button/Button";
import { Input } from "components/Form";
import NotFound from "components/not-found/NotFound";
import useFullPageLoader from "hooks/useFullPageLoader";
import useMuiButtonLoader from "hooks/useMuiButtonLoader";
import { toast } from "react-hot-toast";
import Pagination from "react-js-pagination";
import ReactSelect from "react-select";
import { dateFormat } from "utils";
import { getCourseCategory } from "services/master";
import { useLocation, useParams } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import {
  getactivecourseskyc,
  getkycstudents,
} from "services/KYC/KYCcontroller";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { IconButton } from "@mui/material";

const StudentKYCDetails = () => {
  const [submitButtonElement, setSubmitLoading] = useMuiButtonLoader();
  const [allCourseTypes, setAllCourseTypes] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [selectedCourseType, setSelectedCourseType] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [record, setRecord] = useState([]);
  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const location = useLocation();
  const frontpagecourseid = localStorage.getItem("selectedcourseid");
  const frontpagecoursetitle = localStorage.getItem("selectedcoursetitle");

  // Pagination handler
  const paginationHandler = (page) => {
    setActivePage(page);
    getKYCStudents(page);
  };

  // Get KYC students based on filters
  const getKYCStudents = async (page = 1, data) => {
    try {
      showLoader();
      const res = await getkycstudents({
        courseid: selectedCourse?.value || data?.value,
        search: userEmail,
        page,
      });
      hideLoader();

      if (res.code === 200) {
        const students = res.data.students?.rows || [];
        const totalStudents = res.data.students?.count || 0;
        setRecord(students);
        setTotal(totalStudents);
      } else if(res.code === 100){
        toast.error("No Data Found");
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      hideLoader();
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  // Handle search by course
  const searchByCourse = () => {
    if (!selectedCourse) {
      toast.error("Please Select a Course");
    } else if (userEmail) {
      toast.error("Please clear the email or phone field before searching by course.");
    } else {
      setActivePage(1);
      getKYCStudents(1, selectedCourse);
    }
  };

  // Handle search by email/phone
  const searchByEmailPhone = () => {
    if (!userEmail) {
      toast.error("Please Enter Email or Phone");
    } else if (selectedCourse) {
      toast.error("Please clear the course field before searching by email/phone.");
    } else {
      setActivePage(1);
      getKYCStudents(1, { value: userEmail });
    }
  };




  const onChangeHandler = (data) => {
    setSelectedCourse(data);
  };

  React.useLayoutEffect(() => {
    if (frontpagecourseid && frontpagecoursetitle) {
      let data;
      data = { label: frontpagecoursetitle, value: frontpagecourseid }
      setSelectedCourse(data);
      searchByCourse();
      localStorage.removeItem("selectedcourseid")
      localStorage.removeItem("selectedcoursetitle")
    }

    const getAllCourseTypes = async () => {
      try {
        showLoader();
        const res = await getCourseCategory();
        hideLoader();

        if (res.code === 200) {
          setAllCourseTypes(
            res.data.courseCategories?.map((data) => {
              return { label: data.name, value: data.id };
            }) || []
          );
        } else {
          toast.error(res.message);
        }
      } catch (err) {
        hideLoader();
        toast.error(err?.response?.data?.message || err.message);
      }
    };

    const getAllActiveCourses = async () => {
      try {
        showLoader();
        const res = await getactivecourseskyc();
        hideLoader();

        if (res.code === 200) {
          setAllCourses(
            res.data.courses?.map((data) => {
              return { label: data.CourseTitle, value: data.CourseID };
            }) || []
          );
        } else {
          toast.error(res.message);
        }
      } catch (err) {
        hideLoader();
        toast.error(err?.response?.data?.message || err.message);
      }
    };

    getAllCourseTypes();
    getAllActiveCourses();
  }, []);

  return (
    <React.Fragment>
      <title>Shubhra Viraj | Student KYC Details</title>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="search_wrap">
              <div className="SearchBox"></div>
              <h4 className="Title">Student KYC Details</h4>
            </div>
            <div className="SearchBox mt-3" style={{ width: "60%" }}>
              <div className="row w-100">
                {/* Course Selection Input */}
                <div className="col-sm-12 mui-auto-complete mb-3" style={{ display: "flex", gap: "20px" }}>
                  <ReactSelect
                    className={`w-100 react-select`}
                    placeholder="Select Course"
                    options={allCourses}
                    value={selectedCourse}
                    onChange={onChangeHandler}
                    isSearchable={true}
                  />
                  <Button className="col-sm-2" onClick={searchByCourse} style={{ width: "200px" }}>Search by Course</Button>
                </div>

                {/* OR Text */}
                <div className="col-sm-12" style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "18px", textAlign: "center", margin: "-10px 0", marginBottom:"3px" }}>
                  <span>OR</span>
                </div>

                {/* Email/Phone Input */}
                <div className="col-sm-12 mb-3" style={{ display: "flex", gap: "20px" }}>
                  <Input
                    className={"form-control"}
                    placeholder="Enter User Email Address or Phone"
                    value={userEmail}
                    onChange={({ target }) => setUserEmail(target.value)}
                    type="text"
                  />
                  <Button className="col-sm-2" onClick={searchByEmailPhone} style={{ width: "300px" }}>Search by Email/Phone</Button>
                </div>
              </div>

            </div>
            <hr className="border border-danger" />
            {loader}
            <div className="row mt-3">
              <div className="col-sm-12">
                <div className="TableList overflow-auto table-responsive p-0">
                  <table className="position-relative">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Student Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Profile</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!loader &&
                        record.map((student, index) => (
                          <tr key={index}>
                            <td>{++index + (activePage - 1) * 10}</td>
                            <td>{`${student.firstName} ${student.middleName} ${student.lastName}`}</td>
                            <td>{student.EmailID}</td>
                            <td>{student.Mobile}</td>
                            <td>
                              <Link
                                to={`/kyc-management/student-kyc-action/${student?.Mobile || student?.EmailID}`}
                                state={{ courseid: selectedCourse.value, coursetitle: selectedCourse.label }}
                              >
                                <IconButton
                                  aria-label="profile"
                                  className="px-2 py-0"
                                  variant="outlined"
                                >
                                  <PersonIcon sx={{ color: "#4971B2" }} />
                                </IconButton>
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {!loader && !record.length && (
                    <div>
                      <center><NotFound /></center>
                    </div>
                  )}
                </div>
                <div >
                  {total > 10 ? (
                    <div className="Pagination">
                      <Pagination
                        activePage={activePage}
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        itemsCountPerPage={10}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        onChange={(page) => paginationHandler(page)}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};




export default StudentKYCDetails;
