import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "components/Form";
import { Button } from "components/Button/Button";
import { addPGMasterView } from "../../services/PGController/PGController";
import { toast } from "react-hot-toast";

const AddPGMaster = () => {
  const [data, setData] = useState({
    BankName: "",
    CompanyName: "",
    BillingAddress: "",
    GSTNumber: "",
    MerchantID: "",
    SecretKey: "",
  });

  const [errorField, setErrorField] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // For programmatic navigation

  const onChangeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const submitPGHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Call API to add PG Master
      const response = await addPGMasterView(data);

      if (response.code === 200) {
        toast.success("PG Master added successfully");
        navigate('/pg-management/pg-master'); // Navigate to the PG Master list page
      } else {
        toast.error(`Failed to add PG Master: ${response.message}`);
      }
    } catch (error) {
      toast.error("Error adding PG Master");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-5 d-flex justify-content-between">
                <h4 className="Title">
                  <Link to={-1}>
                    <i className="fa fa-arrow-left"></i>
                  </Link>
                  Add PG Master
                </h4>
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-11 mx-auto">
                <form onSubmit={submitPGHandler}>
                  <div className="form-group row mt-1">
                    <div className="col-sm-12 col-form-label order-1 d-flex flex-column justify-content-end">
                      <div className="row">
                        <div className="col-sm-5 col-form-label">
                          <Input
                            type="text"
                            name="BankName"
                            value={data.BankName}
                            onChange={onChangeHandler}
                            placeholder="Enter Bank Name"
                            className={`form-control`}
                            maxLength={100}
                            label={"Bank Name"}
                            error={errorField.BankName}
                            required
                          />
                        </div>
                        <div className="col-sm-5 col-form-label">
                          <Input
                            type="text"
                            name="CompanyName"
                            value={data.CompanyName}
                            onChange={onChangeHandler}
                            placeholder="Enter Company Name"
                            className={`form-control`}
                            maxLength={100}
                            label={"Company Name"}
                            error={errorField.CompanyName}
                            required
                          />
                        </div>
                        <div className="col-sm-5 col-form-label">
                          <Input
                            type="text"
                            name="BillingAddress"
                            value={data.BillingAddress}
                            onChange={onChangeHandler}
                            placeholder="Enter Billing Address"
                            className={`form-control`}
                            maxLength={100}
                            label={"Billing Address"}
                            error={errorField.BillingAddress}
                            required
                          />
                        </div>
                        <div className="col-sm-5 col-form-label">
                          <Input
                            type="text"
                            name="GSTNumber"
                            value={data.GSTNumber}
                            onChange={onChangeHandler}
                            placeholder="Enter GST Number"
                            className={`form-control`}
                            maxLength={100}
                            label={"GST Number"}
                            error={errorField.GSTNumber}
                            required
                          />
                        </div>
                        <div className="col-sm-5 col-form-label">
                          <Input
                            type="text"
                            name="MerchantID"
                            value={data.MerchantID}
                            onChange={onChangeHandler}
                            placeholder="Enter Merchant ID"
                            className={`form-control`}
                            maxLength={100}
                            label={"Merchant ID"}
                            error={errorField.MerchantID}
                            required
                          />
                        </div>
                        <div className="col-sm-5 col-form-label">
                          <Input
                            type="text"
                            name="SecretKey"
                            value={data.SecretKey}
                            onChange={onChangeHandler}
                            placeholder="Enter Secret Key"
                            className={`form-control`}
                            maxLength={500}
                            label={"Secret Key"}
                            error={errorField.SecretKey}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-center mt-4">
                    <Button type="submit" disabled={loading}>
                      {loading ? "Adding..." : "Add PG Master"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPGMaster;
