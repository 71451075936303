

// import React, { useState, useEffect } from "react";
// import { Button } from "components/Button/Button";
// import NotFound from "components/not-found/NotFound";
// import useFullPageLoader from "hooks/useFullPageLoader";
// import useMuiButtonLoader from "hooks/useMuiButtonLoader";
// import { toast } from "react-hot-toast";
// import Pagination from "react-js-pagination";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import IconButton from "components/Button/IconButton";
// import ModalBox from "components/modal/ModalBox";
// import { getAffiliates } from "services/master/index"; // Importing the API
// import { getAffiliateBillingDetails } from "services/reports/reportController"; // Importing the API
// import { useSearchParams } from "react-router-dom";
// import { dateFormat } from "utils";
// import ReactSelect from "react-select";

// const AffiliateBillingDetails = () => {
//   const [searchParam, setSearchParam] = useSearchParams();
//   let initialPage = +searchParam.get("page") || 1;
//   let search = searchParam.get("query") || "";

//   const [submitButtonElement, setSubmitLoading] = useMuiButtonLoader();
//   const [userMobile, setUserMobile] = useState(search || "");
//   const [userData, setUserData] = useState({});
//   const [record, setRecord] = useState([]);
//   const [total, setTotal] = useState(0);
//   const [loader, showLoader, hideLoader] = useFullPageLoader();
//   const [affiliates, setAffiliates] = useState([]);
//   const [activePage, setActivePage] = useState(initialPage);
//   const [selectedAffiliate, setSelectedAffiliate] = useState(null);

//   useEffect(() => {
//     const fetchAffiliates = async () => {
//       try {
//         const res = await getAffiliates();
//         if (res.code === 200) {
//           const affiliateOptions = res.data.affiliates.map((affiliate) => ({
//             value: affiliate.AffiliateID,
//             label: affiliate.AffiliateName,
//           }));
//           setAffiliates(affiliateOptions);
//         } else {
//           toast.error(res.message);
//         }
//       } catch (err) {
//         toast.error(err?.response?.data?.message || err.message);
//       }
//     };

//     fetchAffiliates();
//   }, []);

//   // Search courses by student email
//   const searchHandler = async (e) => {
//     e.preventDefault();
//     if (!selectedAffiliate) {
//       toast.error("Please Select an Affiliate");
//     } else {
//       setSubmitLoading(true);
//       try {
//         showLoader();
//         const finalData = {
//           AffiliateID: selectedAffiliate.value,
//           page: activePage,
//         };
//         const res = await getAffiliateBillingDetails(finalData);

//         hideLoader();
//         if (res.code === 200) {
//           setSubmitLoading(false);
//           setRecord(res.data.affiliatedetails.rows || []);
//           setTotal(res.data.affiliatedetails.count || 0);
//         } else {
//           setRecord([]);
//           setTotal(0);
//           toast.error(res.message);
//           setSubmitLoading(false);
//         }
//       } catch (err) {
//         toast.error(err?.response?.data?.message || err.message);
//         setSubmitLoading(false);
//       }
//     }
//   };

//   // Handle Pagination
//   const handlePageChange = (pageNumber) => {
//     setActivePage(pageNumber);
//     searchParam.set("page", pageNumber);
//     setSearchParam(searchParam);
//   };

//   useEffect(() => {
//     hideLoader();

//     // eslint-disable-next-line
//   }, [activePage, selectedAffiliate]);

//   return (
//     <React.Fragment>
//       <title>Shubhra Viraj | Affiliate Billing Details</title>
//       <div className="">
//         <div className="WrapperBox">
//           <div className="Small-Wrapper">
//             <div className="search_wrap">
//               <div className="SearchBox"></div>
//               <h4 className="Title">Affiliate Billing Details </h4>
//             </div>
//             <div className=" mt-3">
//               <form onSubmit={searchHandler}>
//                 <div className="row w-100">
//                   <div className={`col-form-label py-0 col-sm-7`}>
//                     <ReactSelect
//                       value={selectedAffiliate}
//                       onChange={setSelectedAffiliate}
//                       options={affiliates}
//                       placeholder="Select Affiliate"
//                     />
//                   </div>

//                   <div className="col-sm-5 d-flex justify-content-between">
//                     <Button type="submit" {...submitButtonElement}>
//                       Search
//                     </Button>
//                   </div>
//                 </div>
//               </form>
//             </div>
//             <hr className="border border-danger" />
//             {loader}
//             <div className="row mt-3">
//               <div className="col-sm-12">
//                 <div className="TableList overflow-auto table-responsive p-0">
//                   Total Records: {total} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{selectedAffiliate && `Affiliate Code: ${selectedAffiliate.value}`}
//                   <br />
//                   <br />
//                   <table className="position-relative">
//                     <thead>
//                       <tr>
//                         <th>S.No</th>
//                         <th>Student Details</th>
//                         <th>Course Name</th>
//                         <th>Course Fee</th>
//                         <th>Discount Given</th>
//                         <th>Balance Amount</th>
//                         <th>Payment Date</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {record && record.length > 0 ? (
//                         record.map((item, index) => (
//                           <tr key={index}>
//                             <td style={{ textAlign: "left" }}>
//                               {index + 1 + (activePage - 1) * 20}
//                             </td>
//                             <td>
//                               {`${item.FirstName} ${item.LastName}`}
//                               <br />
//                               {item.EmailID}
//                               <br />
//                               {item.Mobile}
//                             </td>

//                             <td>{item.CourseName}</td>
//                             <td>{item.CourseFees}</td>
//                             <td>{item.Discount}</td>
//                             <td>{item.BalancePayment}</td>
//                             <td>{dateFormat(item.AddedOn)}</td>
//                           </tr>
//                         ))
//                       ) : (
//                         <NotFound cols={7} />
//                       )}
//                     </tbody>
//                   </table>
//                 </div>
//                 {total > 10 ? (
//                   <div className="Pagination">
//                     <Pagination
//                       activePage={activePage}
//                       previousLabel={"previous"}
//                       nextLabel={"next"}
//                       itemsCountPerPage={10}
//                       totalItemsCount={total}
//                       pageRangeDisplayed={5}
//                       onChange={(page) => handlePageChange(page)}
//                     />
//                   </div>
//                 ) : null}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// };

// export default AffiliateBillingDetails;


import React, { useState, useEffect } from "react";
import { Button } from "components/Button/Button";
import NotFound from "components/not-found/NotFound";
import useFullPageLoader from "hooks/useFullPageLoader";
import useMuiButtonLoader from "hooks/useMuiButtonLoader";
import { toast } from "react-hot-toast";
import Pagination from "react-js-pagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "components/Button/IconButton";
import ModalBox from "components/modal/ModalBox";
import { getAffiliates } from "services/master/index";
import { getAffiliateBillingDetails } from "services/reports/reportController";
import { useSearchParams } from "react-router-dom";
import { dateFormat } from "utils";
import ReactSelect from "react-select";

const AffiliateBillingDetails = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  let initialPage = +searchParam.get("page") || 1;
  let search = searchParam.get("query") || "";

  const [submitButtonElement, setSubmitLoading] = useMuiButtonLoader();
  const [userMobile, setUserMobile] = useState(search || "");
  const [userData, setUserData] = useState({});
  const [record, setRecord] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [affiliates, setAffiliates] = useState([]);
  const [activePage, setActivePage] = useState(initialPage);
  const [selectedAffiliate, setSelectedAffiliate] = useState(null);

  useEffect(() => {
    const fetchAffiliates = async () => {
      try {
        const res = await getAffiliates();
        if (res.code === 200) {
          const affiliateOptions = res.data.affiliates.map((affiliate) => ({
            value: affiliate.AffiliateID,
            label: affiliate.AffiliateName,
          }));
          setAffiliates(affiliateOptions);
        } else {
          toast.error(res.message);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || err.message);
      }
    };

    fetchAffiliates();
  }, []);

  const searchHandler = async (e) => {
    e.preventDefault();
    if (!selectedAffiliate) {
      toast.error("Please Select an Affiliate");
    } else {
      setSubmitLoading(true);
      try {
        showLoader();
        const finalData = {
          AffiliateID: selectedAffiliate.value,
          page: activePage,
        };
        const res = await getAffiliateBillingDetails(finalData);

        hideLoader();
        if (res.code === 200) {
          setSubmitLoading(false);
          setRecord(res.data.affiliatedetails.rows || []);
          setTotal(res.data.affiliatedetails.count || 0);
        } else {
          setRecord([]);
          setTotal(0);
          toast.error(res.message);
          setSubmitLoading(false);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || err.message);
        setSubmitLoading(false);
      }
    }
  };

  const handlePageChange = async (pageNumber) => {
    setActivePage(pageNumber);
    searchParam.set("page", pageNumber);
    setSearchParam(searchParam);

    try {
      showLoader();
      const finalData = {
        AffiliateID: selectedAffiliate.value,
        page: pageNumber,
      };
      const res = await getAffiliateBillingDetails(finalData);

      hideLoader();
      if (res.code === 200) {
        setRecord(res.data.affiliatedetails.rows || []);
        setTotal(res.data.affiliatedetails.count || 0);
      } else {
        setRecord([]);
        setTotal(0);
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  useEffect(() => {
    if (selectedAffiliate) {
      searchHandler({ preventDefault: () => {} });
    }
    // eslint-disable-next-line
  }, [activePage]);

  return (
    <React.Fragment>
      <title>Shubhra Viraj | Affiliate Billing Details</title>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="search_wrap">
              <div className="SearchBox"></div>
              <h4 className="Title">Affiliate Billing Details </h4>
            </div>
            <div className=" mt-3">
              <form onSubmit={searchHandler}>
                <div className="row w-100">
                  <div className={`col-form-label py-0 col-sm-7`}>
                    <ReactSelect
                      value={selectedAffiliate}
                      onChange={setSelectedAffiliate}
                      options={affiliates}
                      placeholder="Select Affiliate"
                    />
                  </div>

                  <div className="col-sm-5 d-flex justify-content-between">
                    <Button type="submit" {...submitButtonElement}>
                      Search
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            <hr className="border border-danger" />
            {loader}
            <div className="row mt-3">
              <div className="col-sm-12">
                <div className="TableList overflow-auto table-responsive p-0">
                  Total Records: {total} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{selectedAffiliate && `Affiliate Code: ${selectedAffiliate.value}`}
                  <br />
                  <br />
                  <table className="position-relative">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Student Details</th>
                        <th>Course Name</th>
                        <th>Course Fee</th>
                        <th>Discount Given</th>
                        <th>Balance Amount</th>
                        <th>Payment Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {record && record.length > 0 ? (
                        record.map((item, index) => (
                          <tr key={index}>
                            <td style={{ textAlign: "left" }}>
                              {index + 1 + (activePage - 1) * 20}
                            </td>
                            <td>
                              {`${item.FirstName} ${item.LastName}`}
                              <br />
                              {item.EmailID}
                              <br />
                              {item.Mobile}
                            </td>

                            <td>{item.CourseName}</td>
                            <td>{item.CourseFees}</td>
                            <td>{item.Discount}</td>
                            <td>{item.BalancePayment}</td>
                            <td>{dateFormat(item.AddedOn)}</td>
                          </tr>
                        ))
                      ) : (
                        <NotFound cols={7} />
                      )}
                    </tbody>
                  </table>
                </div>
                {total > 10 ? (
                  <div className="Pagination">
                    <Pagination
                      activePage={activePage}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      itemsCountPerPage={10}
                      totalItemsCount={total}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AffiliateBillingDetails;
