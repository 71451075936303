import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { Button } from "components/Button/Button";
import toast from "react-hot-toast";

import { getAdmissionSummaryReport,getAdmissionCount } from "services/reports/reportController"; // Import your API
import { getAffiliates } from "services/master/index"; // Import your affiliates API

const AdmissionSummaryReport = () => {
  const [courseOptions, setCourseOptions] = useState([]); // State to store all course options
  const [filteredCourses, setFilteredCourses] = useState([]); // State to store filtered courses
  const [affiliateOptions, setAffiliateOptions] = useState([]); // State to store affiliate options
  const [selectedCourseType, setSelectedCourseType] = useState(""); // State for the selected course type
  const [selectedCourse, setSelectedCourse] = useState(null); // State for the selected course
  const [selectedAffiliate, setSelectedAffiliate] = useState(null); // State for the selected affiliate
  const [showResults, setShowResults] = useState(false); // State to control visibility of results
  const [totalAdmissions, setTotalAdmissions] = useState(0);
  const [accessGiven, setAccessGiven] = useState(0);
  const [missing, setMissing] = useState(0);


  // Fetch course data from the API
  useEffect(() => {
    const fetchCourses = async () => {
      console.log("Fetching courses for type:", selectedCourseType);

      try {
        const data = {
          coursetype: selectedCourseType,
          search: "",
        };

        // Fetch the course data using the API
        const response = await getAdmissionSummaryReport(data);

        // Check if response contains data and map to ReactSelect options format
        if (response && response.data && response.data.courses) {
          const courseList = response.data.courses.map((course) => ({
            label: course.CourseTitle,
            value: course.CourseID,
          }));
          console.log("Fetched course options:", courseList);
          setCourseOptions(courseList); // Update state with course options
          setFilteredCourses(courseList); // Initialize filtered courses
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses(); // Call the fetch function on mount
  }, [selectedCourseType]); // Refetch when course type changes

  // Handle validation before search using toast
  const validateFields = () => {
    if (!selectedCourseType) {
      toast.error("Please select a course type");
      return false;
    }
    if (!selectedCourse) {
      toast.error("Please select a course");
      return false;
    }
    if (!selectedAffiliate) {
      toast.error("Please select an affiliate");
      return false;
    }
    return true;
  };



  // Fetch affiliate data from the API
  useEffect(() => {
    const fetchAffiliates = async () => {
      try {
        const response = await getAffiliates();

        // Check if response contains data and map to ReactSelect options format
        if (response && response.data && response.data.affiliates) {
          const affiliateList = response.data.affiliates.map((affiliate) => ({
            label: affiliate.AffiliateName,
            value: affiliate.AffiliateID,
          }));
          console.log("Fetched affiliate options:", affiliateList);
          setAffiliateOptions(affiliateList); // Update state with affiliate options
        }
      } catch (error) {
        console.error("Error fetching affiliates:", error);
      }
    };

    fetchAffiliates(); // Call the fetch function on mount
  }, []); // Empty dependency array to fetch affiliates only on mount

  // Handle Search Button Click
  const handleSearch = async (event) => {
    event.preventDefault();

    if (validateFields()) {
      try {
        const data = {
          affiliateid: selectedAffiliate?.value || "",
          courseid: selectedCourse?.value || "",
          search: "",
        };

        const response = await getAdmissionCount(data);
        if (response && response.data) {
          setTotalAdmissions(response.data.totaladmissions || 0);
          setAccessGiven(response.data.accessgiven || 0);
          setMissing(response.data.missing || 0);
          setShowResults(true);
        }
      } catch (error) {
        console.error("Error fetching admission count:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <title>Shubhra Viraj | Admission Summary Report</title>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="search_wrap">
              <div className="SearchBox"></div>
              <h4 className="Title">Admission Summary Report</h4>
            </div>
            <div className=" mt-3">
              <form>
                <div className="row w-100">
                  {/* Course Type Select */}
                  <div className="col-sm-3 mui-auto-complete">
                    <ReactSelect
                      className="w-100 react-select"
                      placeholder="Select Course Type"
                      options={[
                        { label: "Active", value: "Active" },
                        { label: "Inactive", value: "Inactive" },
                      ]}
                      isSearchable={true}
                      onChange={(data) =>
                        setSelectedCourseType(data ? data.value : "")
                      }
                    />
                  </div>

                  {/* Course Select with dynamic API data */}
                  <div className="col-sm-3 mui-auto-complete">
                    <ReactSelect
                      className="w-100 react-select"
                      placeholder="Select Course"
                      options={filteredCourses} // Bind filtered course options here
                      onChange={(data) => setSelectedCourse(data)} // Handle selection
                      isSearchable={true}
                    />
                  </div>

                  {/* Affiliate Select with API data */}
                  <div className="col-sm-3 mui-auto-complete">
                    <ReactSelect
                      className="w-100 react-select"
                      placeholder="Select Affiliate"
                      options={affiliateOptions} // Bind affiliate options here
                      onChange={(data) => setSelectedAffiliate(data)} // Handle selection
                      isSearchable={true}
                    />
                  </div>

                  {/* Search Button */}
                  <div className="col-sm-1 mx-4">
                  <Button type="submit" onClick={handleSearch}>
                      Search
                    </Button>
                  </div>
                </div>
              </form>
            </div>

            <hr className="border border-danger" />
          </div>

          {/* Display Summary Cards only if showResults is true */}
          {showResults && (
            <div className="Small-Wrapper my-3">
              <div className="search_wrap">
                <div className="SearchBox">
                  <div className="div_wrap"></div>
                  <div className="div_no_wrap mx-1 row"></div>
                </div>
                <h4 className="Title">Admission Summary Report</h4>
              </div>
              <div className="CardList d-flex justify-content-between">
                <div className="card" style={{ width: "30%" }}>
                  <div className="card-body">
                    <h5 className="card-title">Admissions</h5>
                    <p className="card-text">
                      <strong>Count:</strong> {totalAdmissions}
                      {/* Replace this with actual count variable */}
                    </p>
                  </div>
                </div>

                <div className="card" style={{ width: "30%" }}>
                  <div className="card-body">
                    <h5 className="card-title">Access Given</h5>
                    <p className="card-text">
                      <strong>Count:</strong> {accessGiven}
                      {/* Replace this with actual count variable */}
                    </p>
                  </div>
                </div>

                <div className="card" style={{ width: "30%" }}>
                  <div className="card-body">
                    <h5 className="card-title">Mismatch</h5>
                    <p className="card-text">
                      <strong>Count:</strong> {missing}
                      {/* Replace this with actual count variable */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdmissionSummaryReport;
