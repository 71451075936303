import React, { useState, useEffect, useLayoutEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Input } from "components/Form";
import { Button } from "components/Button/Button";
import {
  getPGMasterView,
  updatePGMasterView,
} from "../../services/PGController/PGController";
import { toast } from "react-hot-toast";

const EditPGMaster = () => {
  const { id } = useParams();
  const navigate = useNavigate(); // For programmatic navigation

  const [data, setData] = useState({
    BankName: "",
    CompanyName: "",
    BillingAddress: "",
    GSTNumber: "",
    MerchantID: "",
    SecretKey: "",
  });

  const [errorField, setErrorField] = useState({});
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    const fetchPGDetails = async (id) => {
      setLoading(true);
      try {
        const response = await getPGMasterView({ id });
        console.log(response);
        if (response.code === 200) {
          const { pgmaster } = response.data;
          setData({
            BankName: pgmaster.BankName || "",
            CompanyName: pgmaster.CompanyName || "",
            BillingAddress: pgmaster.BillingAddress || "",
            GSTNumber: pgmaster.GSTNumber || "",
            MerchantID: pgmaster.MerchantID || "",
            SecretKey: pgmaster.SecretKey || "",
          });
        } else {
          console.error("Failed to fetch PG Master details:", response.message);
          toast.error(response.message); // Show error toast
        }
      } catch (error) {
        console.error("Failed to fetch PG Master details", error);
        toast.error("Error fetching PG Master details"); // Show error toast
      } finally {
        setLoading(false);
      }
    };

    fetchPGDetails(id);
  }, [id]);

  const validate = () => {
    const errors = {};
    let isValid = true;

    // Validate each field
    if (!data.BankName) {
      errors.BankName = "Bank Name is required";
      isValid = false;
    }
    if (!data.CompanyName) {
      errors.CompanyName = "Company Name is required";
      isValid = false;
    }
    if (!data.BillingAddress) {
      errors.BillingAddress = "Billing Address is required";
      isValid = false;
    }
    if (!data.GSTNumber) {
      errors.GSTNumber = "GST Number is required";
      isValid = false;
    }
    if (!data.MerchantID) {
      errors.MerchantID = "Merchant ID is required";
      isValid = false;
    }
    if (!data.SecretKey) {
      errors.SecretKey = "Secret Key is required";
      isValid = false;
    }

    setErrorField(errors);
    return isValid;
  };

  const onChangeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const submitPGHandler = async (e) => {
    e.preventDefault();

    // Validate form data
    if (!validate()) {
      return; // Stop submission if validation fails
    }

    try {
      setLoading(true);
      const finalData = {
        id: id,
        BankName: data.BankName,
        CompanyName: data.CompanyName,
        BillingAddress: data.BillingAddress,
        GSTNumber: data.GSTNumber,
        MerchantID: data.MerchantID,
        SecretKey: data.SecretKey,
      };
      const response = await updatePGMasterView(finalData);

      if (response.code === 200) {
        toast.success("PG Master updated successfully");
        // Optionally, redirect or show a success message
        navigate('/pg-management/pg-master'); // Redirect after successful update
      } else {
        toast.error("Failed to update PG Master: " + response.message);
      }
    } catch (error) {
      toast.error("Error updating PG Master");
      console.error("Error updating PG Master:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="">
      <div className="WrapperBox">
        <div className="Small-Wrapper">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-5 d-flex justify-content-between">
              <h4 className="Title">
                <Link to={-1}>
                  <i className="fa fa-arrow-left"></i>
                </Link>
                Edit PG Master
              </h4>
            </div>
          </div>

          <div className="row mt-1">
            <div className="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-11 mx-auto">
              <form onSubmit={submitPGHandler}>
                <div className="form-group row mt-1">
                  <div className="col-sm-12 col-form-label order-1 d-flex flex-column justify-content-end">
                    <div className="row">
                      <div className="col-sm-5 col-form-label">
                        <Input
                          type="text"
                          name="BankName"
                          value={data.BankName}
                          onChange={onChangeHandler}
                          placeholder="Enter Bank Name"
                          className="form-control"
                          maxLength={100}
                          label="Bank Name"
                          error={errorField.BankName}
                          required
                        />
                      </div>
                      <div className="col-sm-5 col-form-label">
                        <Input
                          type="text"
                          name="CompanyName"
                          value={data.CompanyName}
                          onChange={onChangeHandler}
                          placeholder="Enter Company Name"
                          className="form-control"
                          maxLength={100}
                          label="Company Name"
                          error={errorField.CompanyName}
                          required
                        />
                      </div>
                      <div className="col-sm-5 col-form-label">
                        <Input
                          type="text"
                          name="BillingAddress"
                          value={data.BillingAddress}
                          onChange={onChangeHandler}
                          placeholder="Enter Billing Address"
                          className="form-control"
                          maxLength={100}
                          label="Billing Address"
                          error={errorField.BillingAddress}
                          required
                        />
                      </div>
                      <div className="col-sm-5 col-form-label">
                        <Input
                          type="text"
                          name="GSTNumber"
                          value={data.GSTNumber}
                          onChange={onChangeHandler}
                          placeholder="Enter GST Number"
                          className="form-control"
                          maxLength={100}
                          label="GST Number"
                          error={errorField.GSTNumber}
                          required
                        />
                      </div>
                      <div className="col-sm-5 col-form-label">
                        <Input
                          type="text"
                          name="MerchantID"
                          value={data.MerchantID}
                          onChange={onChangeHandler}
                          placeholder="Enter Merchant ID"
                          className="form-control"
                          maxLength={100}
                          label="Merchant ID"
                          error={errorField.MerchantID}
                          required
                        />
                      </div>
                      <div className="col-sm-5 col-form-label">
                        <Input
                          type="text"
                          name="SecretKey"
                          value={data.SecretKey}
                          onChange={onChangeHandler}
                          placeholder="Enter Secret Key"
                          className="form-control"
                          maxLength={500}
                          label="Secret Key"
                          error={errorField.SecretKey}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-center mt-4">
                  <Button type="submit">Update PG Master</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPGMaster;
