import { Button } from "components/Button/Button";
import useFullPageLoader from "hooks/useFullPageLoader";
import useMuiButtonLoader from "hooks/useMuiButtonLoader";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import ReactDatePicker from "react-datepicker";
import NotFound from "components/not-found/NotFound";
import Pagination from "react-js-pagination";
import { Table } from "react-bootstrap";
import useDebounce from "hooks/useDebounce";
import { dateFormat, simpleDateFormat, simpleDateFormatForSearch } from "utils";
import * as XLSX from "xlsx";
import { getKnoxApiErrorList } from "services/reports/reportController";
import FormControl from "@mui/material/FormControl";

export default function KnoxApiErrorLog() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [flag, setFlag] = useState(false);
  const [total, setTotal] = useState(0);
  const [record, setRecord] = useState([]);
  const [excelRecord, setExcelRecord] = useState([]);
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState({});
  const debounceValue = useDebounce(search, 300);
  const [submitButtonElement, setSubmitLoading] = useMuiButtonLoader();
  const [submitButtonElement2, setSubmitLoading2] = useMuiButtonLoader();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [sign, setSign] = useState(2);

  const searchHandler = async (e) => {
    e.preventDefault();
    if (sign === 1) {
      if (!startDate && !endDate) {
        toast.error("Please select both Start Date and End Date.");
      } else if (!startDate) {
        toast.error("Please select Start Date.");
      } else if (!endDate) {
        toast.error("Please select End Date.");
      } else {
        getKnoxList(activePage, debounceValue, limit);
        return true;
      }
    }
    return false;
  };

  const getKnoxList = async (
    page = activePage,
    search = debounceValue,
    pageLimit = limit
  ) => {
    if (searchHandler()) {
      let otherSearch = {};
      for (let [key, value] of Object.entries(searchData)) {
        const bool = key.split("-")?.[1] === "date";
        if (bool) {
          Object.assign(otherSearch, {
            [key.split("-")?.[0]]: simpleDateFormatForSearch(value),
          });
        } else {
          Object.assign(otherSearch, { [key]: value });
        }
      }
      setSubmitLoading(true);
      try {
        showLoader();
        const finalData = {
          isDownload: 0,
          startDate: simpleDateFormat(startDate),
          endDate: simpleDateFormat(endDate),
          page,
          search,
          limit: pageLimit,
          ...otherSearch,
        };
        const res = await getKnoxApiErrorList(finalData);
        if (res.code === 200) {
          setRecord(res.data.knoxapierrorlog.rows);
          setTotal(res.data.knoxapierrorlog.count);
          setFlag(true);
        } else {
          toast.error(`Failed to fetch data: ${res.message}`);
        }
      } catch (e) {
        toast.error(`Error: ${e?.response?.data?.message || e.message}`);
      } finally {
        hideLoader();
        setSubmitLoading(false);
      }
    }
  };

  const handleLimitChange = (e) => {
    const newLimit = parseInt(e.target.value, 10);
    setLimit(newLimit);
    getKnoxList(activePage, debounceValue, newLimit);
  };

  useEffect(() => {
    const updatedExcelRecord = record.map((data, i) => ({
      "S.NO": i + (activePage - 1) * limit + 1,
      EMAIL: data.emailid,
      "IMEI NUMBER": data.imei,
      "DEVICE MODEL": data.deviceModel,
      "END DATE": dateFormat(data.assignedLicenseEndDate),
    }));
    setExcelRecord(updatedExcelRecord);
  }, [record]);

  useEffect(() => {
    if (sign === 1) {
      getKnoxList(activePage, debounceValue);
    }
  }, [activePage, sign]);

  const downloadExcelHandler = () => {
    if (flag) {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(excelRecord);
      XLSX.utils.book_append_sheet(wb, ws, "MyKnox");
      XLSX.writeFile(wb, "MyExcel.xlsx");
    } else {
      toast.error("No data available to export.");
    }
  };

  const tableRef = useRef(null);

  return (
    <>
      <title>Shubhra Viraj | Knox Api Error Log</title>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="search_wrap">
              <div className="SearchBox"></div>
              <h4 className="Title">Knox Api Error Log</h4>
            </div>
            <div className=" mt-3">
              <form onSubmit={searchHandler}>
                <div className="row w-100">
                  <div
                    className={`col-form-label py-0 col-sm-4`}
                    style={{ marginTop: "10px" }}
                  >
                    <ReactDatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className={`form-control`}
                      maxDate={new Date()}
                      showIcon
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Select Start Date"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      onChangeRaw={(e) => e.preventDefault()}
                    />
                  </div>
                  <div
                    className={`col-form-label py-0 col-sm-4`}
                    style={{ marginTop: "10px" }}
                  >
                    <ReactDatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      className={`form-control`}
                      minDate={startDate}
                      maxDate={new Date()}
                      showIcon
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Select End Date"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      onChangeRaw={(e) => e.preventDefault()}
                    />
                  </div>
                  <div className="col-sm-2" style={{ marginTop: "11px" }}>
                    <Button type="submit" onClick={() => setSign(1)}>
                      Search
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            <hr className="border border-danger" />
          </div>
          {flag && (
            <div className="Small-Wrapper my-3">
              <div className="search_wrap">
                <div className="SearchBox">
                  <div className="div_wrap"></div>
                  <div className="div_wrap2">
                    <label htmlFor="rows-select" className="form-label-dp">
                      No of rows:
                    </label>
                    <FormControl sx={{ m: 1, minWidth: 40 }} size="small">
                      <select
                        className="form-control-dp"
                        id="rows-select"
                        onChange={handleLimitChange}
                      >
                        {[10, 20, 40, 80, 100].map((num) => (
                          <option value={num} key={num}>
                            {num}
                          </option>
                        ))}
                      </select>
                    </FormControl>
                  </div>
                  <Button
                    className="mx-2"
                    onClick={downloadExcelHandler}
                    {...submitButtonElement2}
                  >
                    Export To Excel
                  </Button>
                </div>
                <h4 className="Title">KNOX API Error Log (Total Count: {total})</h4>
              </div>
              <div className="TableList overflow-auto table-responsive">
                {loader}
                <Table
                  bordered
                  className="table"
                  ref={tableRef}
                  id="my-table-id"
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Email</th>
                      <th>IMEI Number</th>
                      <th>Device Model</th>
                      <th>End Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {record.length > 0 ? (
                      record.map((data, i) => (
                        <tr key={i}>
                          <td>{i + (activePage - 1) * limit + 1}</td>
                          <td style={{ textTransform: "lowercase" }}>
                            {data.emailid}
                          </td>
                          <td>{data.imei}</td>
                          <td>{data.deviceModel}</td>
                          <td>{dateFormat(data.assignedLicenseEndDate)}</td>
                        </tr>
                      ))
                    ) : (
                      <NotFound />
                    )}
                  </tbody>
                </Table>
              </div>
              {/* <div className="pagination">
                <Pagination
                  innerClass="pagination mb-0"
                  activePage={activePage}
                  itemsCountPerPage={limit}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={(page) => setActivePage(page)}
                />
              </div> */}
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={(page) => setActivePage(page)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
