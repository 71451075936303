


// import React, { useEffect, useState } from "react";
// import { useSearchParams, useNavigate } from "react-router-dom";
// import { Button } from "components/Button/Button";
// import Pagination from "react-js-pagination";
// import { Table } from "react-bootstrap";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import IconButton from "components/Button/IconButton";
// import { getPGMasterList, getPGMasterView } from "services/PGController/PGController";
// import NotFound from "components/not-found/NotFound";
// import useFullPageLoader from "hooks/useFullPageLoader";
// import useMuiButtonLoader from "hooks/useMuiButtonLoader";
// import { toast } from "react-hot-toast";

// const PGMaster = () => {
//   const [searchParam, setSearchParam] = useSearchParams();
//   const navigate = useNavigate(); // For programmatic navigation
//   let activePage = +searchParam.get("page") || 1;

//   const [record, setRecord] = useState([]);
//   const [total, setTotal] = useState(0);
//   const [loader, showLoader, hideLoader] = useFullPageLoader();
//   const [submitButtonElement, setSubmitLoading] = useMuiButtonLoader();

//   const fetchData = async (page = 1) => {
//     showLoader();
//     try {
//       const response = await getPGMasterList({ page });
//       if (response.code === 200) {
//         setRecord(response.data.pgmasterlist);
//         setTotal(response.data.totalCount); // Assuming API provides total count
//       } else {
//         // Handle API error response
//         console.error(response.message);
//         toast.error(`Failed to fetch data: ${response.message}`);
//         setRecord([]);
//         setTotal(0);
//       }
//     } catch (error) {
//       // Handle fetch error
//       console.error("Error fetching PG Master list:", error);
//       toast.error("Error fetching PG Master list");
//       setRecord([]);
//       setTotal(0);
//     } finally {
//       hideLoader();
//     }
//   };

//   useEffect(() => {
//     fetchData(activePage);
//   }, [activePage]);

//   // Handle Pagination
//   const handlePageChange = (pageNumber) => {
//     searchParam.set("page", pageNumber);
//     setSearchParam(searchParam);
//     fetchData(pageNumber);
//   };

//   // Handle Edit Click
//   const handleEditClick = async (id) => {
//     showLoader();
//     try {
//       const response = await getPGMasterView({ id });
//       if (response.code === 200) {
//         console.log("Fetched data for edit page:", response.data); // Log the fetched data
//         navigate(`/pg-management/pg-master/edit-pg-master/${id}`, {
//           state: { record: response.data }, // Pass the fetched data to the edit page
//         });
//       } else {
//         console.error("Failed to fetch PG Master details:", response.message);
//         toast.error(`Failed to fetch PG Master details: ${response.message}`);
//       }
//     } catch (error) {
//       console.error("Error fetching PG Master details:", error);
//       toast.error("Error fetching PG Master details");
//     } finally {
//       hideLoader();
//     }
//   };

//   return (
//     <React.Fragment>
//       <title>Shubhra Viraj | PG Master</title>
//       <div className="">
//         <div className="WrapperBox">
//           <div className="Small-Wrapper">
//             <div className="search_wrap">
//               <div className="SearchBox">
//                 <div className="div_no_wrap mx-1 row"></div>
//                 <div>
//                   <Button
//                     to="/pg-management/pg-master/add-pg-master"
//                     type="acl"
//                     role={996613}
//                   >
//                     Add&nbsp;PG&nbsp;Master
//                   </Button>
//                 </div>
//               </div>
//               <h4 className="Title">PG&nbsp;Master</h4>
//             </div>
//             <div className="TableList overflow-auto table-responsive">
//               {loader}
//               <Table bordered className="table">
//                 <thead>
//                   <tr>
//                     <th>S.No</th>
//                     <th>Bank Name</th>
//                     <th>Company Name</th>
//                     <th>Billing Address</th>
//                     <th>GST Number</th>
//                     <th>Merchant ID</th>
//                     <th>Action</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {record.length > 0 ? (
//                     record.map(
//                       (
//                         {
//                           id,
//                           BankName,
//                           CompanyName,
//                           BillingAddress,
//                           GSTNumber,
//                           MerchantID,
//                         },
//                         i
//                       ) => (
//                         <tr key={id}>
//                           <td>{++i + (activePage - 1) * 10}</td>
//                           <td>{BankName}</td>
//                           <td>{CompanyName}</td>
//                           <td>{BillingAddress}</td>
//                           <td>{GSTNumber}</td>
//                           <td>{MerchantID}</td>
//                           <td>
//                             <div className="d-flex justify-content-center">
//                               <IconButton
//                                 aria-label="edit"
//                                 className="px-2 py-0"
//                                 onClick={() => handleEditClick(id)}
//                               >
//                                 <EditIcon sx={{ color: "#4971B2" }} />
//                               </IconButton>

//                               <IconButton
//                                 aria-label="delete"
//                                 className="px-2 py-0"
//                                 type="acl"
//                                 role={996615}
//                               >
//                                 <DeleteIcon sx={{ color: "#ff0000" }} />
//                               </IconButton>
//                             </div>
//                           </td>
//                         </tr>
//                       )
//                     )
//                   ) : (
//                     <NotFound cols={12} />
//                   )}
//                 </tbody>
//               </Table>
//             </div>
//             {total > 10 ? (
//               <div className="Pagination">
//                 <Pagination
//                   activePage={activePage}
//                   previousLabel={"previous"}
//                   nextLabel={"next"}
//                   itemsCountPerPage={10}
//                   totalItemsCount={total}
//                   pageRangeDisplayed={5}
//                   onChange={handlePageChange}
//                 />
//               </div>
//             ) : null}
//           </div>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// };

// export default PGMaster;

import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Button } from "components/Button/Button";
import Pagination from "react-js-pagination";
import { Table } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "components/Button/IconButton";
import { getPGMasterList, getPGMasterView, deletePGMasterView } from "services/PGController/PGController";
import NotFound from "components/not-found/NotFound";
import useFullPageLoader from "hooks/useFullPageLoader";
import useMuiButtonLoader from "hooks/useMuiButtonLoader";
import { toast } from "react-hot-toast";

const PGMaster = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  const navigate = useNavigate(); // For programmatic navigation
  let activePage = +searchParam.get("page") || 1;

  const [record, setRecord] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [submitButtonElement, setSubmitLoading] = useMuiButtonLoader();

  const fetchData = async (page = 1) => {
    showLoader();
    try {
      const response = await getPGMasterList({ page });
      if (response.code === 200) {
        setRecord(response.data.pgmasterlist);
        setTotal(response.data.totalCount); // Assuming API provides total count
      } else {
        // Handle API error response
        console.error(response.message);
        toast.error(`Failed to fetch data: ${response.message}`);
        setRecord([]);
        setTotal(0);
      }
    } catch (error) {
      // Handle fetch error
      console.error("Error fetching PG Master list:", error);
      toast.error("Error fetching PG Master list");
      setRecord([]);
      setTotal(0);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    fetchData(activePage);
  }, [activePage]);

  // Handle Pagination
  const handlePageChange = (pageNumber) => {
    searchParam.set("page", pageNumber);
    setSearchParam(searchParam);
    fetchData(pageNumber);
  };

  // Handle Edit Click
  const handleEditClick = async (id) => {
    showLoader();
    try {
      const response = await getPGMasterView({ id });
      if (response.code === 200) {
        console.log("Fetched data for edit page:", response.data); // Log the fetched data
        navigate(`/pg-management/pg-master/edit-pg-master/${id}`, {
          state: { record: response.data }, // Pass the fetched data to the edit page
        });
      } else {
        console.error("Failed to fetch PG Master details:", response.message);
        toast.error(`Failed to fetch PG Master details: ${response.message}`);
      }
    } catch (error) {
      console.error("Error fetching PG Master details:", error);
      toast.error("Error fetching PG Master details");
    } finally {
      hideLoader();
    }
  };

  // Handle Delete Click
  const handleDeleteClick = async (id) => {
      showLoader();
      try {
        const response = await deletePGMasterView({ id });
        if (response.code === 200) {
          toast.success("Record deleted successfully");
          fetchData(activePage); // Refresh data after deletion
        } else {
          console.error("Failed to delete PG Master:", response.message);
          toast.error(`Failed to delete PG Master: ${response.message}`);
        }
      } catch (error) {
        console.error("Error deleting PG Master:", error);
        toast.error("Error deleting PG Master");
      } finally {
        hideLoader();
      }
  };

  return (
    <React.Fragment>
      <title>Shubhra Viraj | PG Master</title>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="search_wrap">
              <div className="SearchBox">
                <div className="div_no_wrap mx-1 row"></div>
                <div>
                  <Button
                    to="/pg-management/pg-master/add-pg-master"
                    type="acl"
                    role={996613}
                  >
                    Add&nbsp;PG&nbsp;Master
                  </Button>
                </div>
              </div>
              <h4 className="Title">PG&nbsp;Master</h4>
            </div>
            <div className="TableList overflow-auto table-responsive">
              {loader}
              <Table bordered className="table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Bank Name</th>
                    <th>Company Name</th>
                    <th>Billing Address</th>
                    <th>GST Number</th>
                    <th>Merchant ID</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {record.length > 0 ? (
                    record.map(
                      (
                        {
                          id,
                          BankName,
                          CompanyName,
                          BillingAddress,
                          GSTNumber,
                          MerchantID,
                        },
                        i
                      ) => (
                        <tr key={id}>
                          <td>{++i + (activePage - 1) * 10}</td>
                          <td>{BankName}</td>
                          <td>{CompanyName}</td>
                          <td>{BillingAddress}</td>
                          <td>{GSTNumber}</td>
                          <td>{MerchantID}</td>
                          <td>
                            <div className="d-flex justify-content-center">
                              <IconButton
                                aria-label="edit"
                                className="px-2 py-0"
                                onClick={() => handleEditClick(id)}
                              >
                                <EditIcon sx={{ color: "#4971B2" }} />
                              </IconButton>

                              <IconButton
                                aria-label="delete"
                                className="px-2 py-0"
                                type="acl"
                                role={996615}
                                onClick={() => handleDeleteClick(id)}
                              >
                                <DeleteIcon sx={{ color: "#ff0000" }} />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <NotFound cols={12} />
                  )}
                </tbody>
              </Table>
            </div>
            {total > 10 ? (
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PGMaster;
