import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { isLoggedIn } from "utils";

export const getStudentList = async (data) => {
  return decryptData(
    await axios.get(
      `/student?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// _________ get course list via user email __________
export const getCoursesViaStudentEmail = async (data) => {
  return decryptData(
    await axios.get(
      `/student/course/list?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getGPSCoursesStudentList = async (data) => {
  return decryptData(
    await axios.get(
      `/student/gps-course/list?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
// _________ get course list via user email __________
export const gpsAccessPermission = async (data) => {
  return decryptData(
    await axios.get(
      `/student/course/gps/access?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
// _____________  Get Courses by course type  ___________
export const getCourseListByType = async (data) => {
  return decryptData(
    await axios.get(
      `/student/course?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// _____________  Get Student List by Course   ___________
export const getStudentListByCourse = async (data) => {
  return decryptData(
    await axios.get(
      `/student/list?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getStudentListByCourseWithoutPagination = async (data) => {
  return decryptData(
    await axios.get(
      `/student/list/without/pagination?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// _____________  Get course list by student   ___________
export const getCoursesByStudent = async (data) => {
  return decryptData(
    await axios.get(
      `/student/free/access/course/list?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// _____________  bulk assign student to course    ___________
export const bulkAssignUserToCourse = async (data) => {
  return decryptData(await axios.post(`/student/bulk-assign`, data));
};

// _____________  Add Course to user by admin   ___________
export const addFreeCourseAccess = async (data) => {
  return decryptData(
    await axios.get(
      `/student/grant/free/access/course?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// _____________  Get student profile detail   ___________
export const getStudentProfileDetail = async (data) => {
  return decryptData(
    await axios.get(
      `/student/profile?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// _____________  Update User Profile   ___________
export const updateUserProfile = async (data) => {
  return decryptData(
    await axios.post(`/student/update/profile`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};

// _____________  Update User Documents   ___________
export const updateUserDocument = async (data, option) => {
  return decryptData(
    await axios.post(`/student/update/document`, data, option)
  );
};

export const getOldStudentList = async (data) => {
  return decryptData(
    await axios.get(
      `/old-student-list?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getOldStudentListbyEmailID = async (data) => {
  return decryptData(
    await axios.get(
      `/get-old-student-by-emailid?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const addOldStudentDetails = async (data) => {
  return decryptData(
    await axios.post(`/add-old-student-details`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};

export const verifyMobile = async (data) => {
  return decryptData(
    await axios.post(`/verify-mobile-number`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};

export const courseAccessReport = async (data) => {
  return decryptData(
    await axios.get(
      `/get-course-access-report?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getdetailsofstudentViaStudentEmailorphone = async (data) => {
  return decryptData(
    await axios.get(
      `/get-student-details?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getCoursetestforStudents = async (data) => {
  return decryptData(
    await axios.get(
      `/get-course-test-for-students?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const getCourseforStudents = async (data) => {
  return decryptData(
    await axios.get(
      `/get-course-for-students?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const getWatchingHoursforStudents = async (data) => {
  return decryptData(
    await axios.get(
      `/get-watching-hours-for-student?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// *********************************** Student Details ***********************************************
export const getStudentDetail = async (data) => {
  return decryptData(
    await axios.get(
      `/get-student-and-course-details?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
