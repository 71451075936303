import { Button } from "components/Button/Button";
import useFullPageLoader from "hooks/useFullPageLoader";
import useMuiButtonLoader from "hooks/useMuiButtonLoader";
import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import Select from "react-select";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useRef } from "react";
import { Link ,useNavigate} from "react-router-dom";

import CardMedia from "@mui/material/CardMedia";

import {
  getCoursesViaStudentEmail,
  getStudentProfileDetail,
} from "services/student/studentController";
import { updatekycflag } from "services/KYC/KYCcontroller";
import { useLocation, useParams } from "react-router-dom";
import ModalBox from "components/modal/ModalBox";

const StudentKYCAction = () => {
  const { mobile } = useParams();
  console.log("mobile", mobile);
  const navigate = useNavigate();
  const [userUpdateData, setUserUpdateData] = useState({});
  const [errorField, setErrorField] = React.useState({});

  const imageRef = useRef(null);
  const addressRef = useRef(null);
  const photoRef = useRef(null);

  const [idProof, setIdProof] = useState(null);
  const [addressProof, setAddressProof] = useState(null);
  const [idPhoto, setIdPhoto] = useState(null);

  const [dropdownMessage, setDropdownMessage] = useState([]);

  const [userEmail, setUserEmail] = useState(mobile);
  const [userData, setUserData] = useState({});
  const [UserID, setUserID] = useState("");
  const [showKycModal1, setShowKycModal1] = useState(false);
  const [showKycModal2, setShowKycModal2] = useState(false);

  const location = useLocation();
  const { courseid, coursetitle } = location.state || {};
  if (courseid && coursetitle) {
    localStorage.setItem("selectedcourseid", courseid);
    localStorage.setItem("selectedcoursetitle", coursetitle);
//     const samplevariable = localStorage.getItem("selectedcourseid");
// alert(samplevariable);
  }

  const queryParams = new URLSearchParams(location.search);
  const hideButtons = queryParams.get("hideButtons") === "true";

  const [submitButtonElement, setSubmitLoading] = useMuiButtonLoader();

  const openKycModal1 = () => setShowKycModal1(true);
  const closeKycModal1 = () => setShowKycModal1(false);

  const openKycModal2 = () => setShowKycModal2(true);
  const closeKycModal2 = () => setShowKycModal2(false);

  const [isSameAddress, setIsSameAddress] = React.useState(
    userUpdateData.PHouseNo === userUpdateData.THouseNo &&
      userUpdateData.PStreet === userUpdateData.TStreet &&
      userUpdateData.PLandmark === userUpdateData.TLandmark &&
      userUpdateData.PCity === userUpdateData.TCity &&
      userUpdateData.PState === userUpdateData.TState &&
      userUpdateData.PZipCode === userUpdateData.TZipCode
  );

  useEffect(() => {
    if (mobile) {
      searchHandler();
    }
  }, [mobile]);

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const searchHandler = async (e) => {
    if (e) e.preventDefault();

    if (!userEmail) {
      toast.error("Please Enter User Email or Phone number");
    } else {
      setSubmitLoading(true);
      try {
        showLoader();
        const finalData = {
          accessType: "normal",
          search: userEmail,
        };
        const res = await getCoursesViaStudentEmail(finalData);

        hideLoader();
        setSubmitLoading(false);
        if (res.code === 200) {
          setUserData(res.data.userData || {});
          const detailRes = await getStudentProfileDetail({
            studentId: res.data.userData.UserID,
          });

          if (detailRes.code === 200) {
            setUserUpdateData({
              ...userUpdateData,
              ...detailRes.data.userDetails,
            });
            setIdProof(detailRes.data.userDetails.IDProof);
            setAddressProof(detailRes.data.userDetails.addressProof);
            setIdPhoto(detailRes.data.userDetails.IDPhoto);
            setUserID(detailRes.data.userDetails.UserID);
          }
        } else {
          setUserData({});
          toast.error(res.message);
          setSubmitLoading(false);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || err.message);
        setSubmitLoading(false);
      }
    }
  };

  //   user selection same as present function
  React.useEffect(() => {
    if (isSameAddress) {
      const { THouseNo, TStreet, TLandmark, TCity, TState, TZipCode } =
        userUpdateData;
      setUserUpdateData({
        ...userUpdateData,
        PHouseNo: THouseNo,
        PStreet: TStreet,
        PLandmark: TLandmark,
        PCity: TCity,
        PState: TState,
        PZipCode: TZipCode,
      });
    } else {
      setUserUpdateData({
        ...userUpdateData,
        PHouseNo: "",
        PStreet: "",
        PLandmark: "",
        PCity: "",
        PState: "",
        PZipCode: "",
      });
    }
    // eslint-disable-next-line
  }, [isSameAddress]);

  // Handle Do KYC
  const handleDoKyc = async () => {
    try {
      const commentsArray = dropdownMessage.map(option => option.label);
      const res = await updatekycflag({
        UserID,
        type: "dokyc",
        comment: commentsArray,
      });
      if (res.code === 200) {
        toast.success("KYC request sent succesfully");
        // setIdCheck(flagId);
        // setAddressCheck(flagAddress);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  // Handle Verify KYC
  const handleVerifyKyc = async () => {
    try {
      const res = await updatekycflag({
        UserID,
        type: "verifykyc",
      });
      if (res.code === 200) {
        toast.success("KYC verified successfully");
        // setIdCheck(true);
        // setAddressCheck(true);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const handleFullscreen = (ref) => {
    if (ref.current) {
      // Apply custom styles before requesting fullscreen
      ref.current.style.width = "100%";
      ref.current.style.height = "100%";
      ref.current.style.position = "fixed";
      ref.current.style.top = "0";
      ref.current.style.left = "0";
      ref.current.style.zIndex = "9999";
      ref.current.style.backgroundColor = "black";
      ref.current.style.objectFit = "contain"; // Or "cover", depending on your needs

      // Request fullscreen
      ref.current.requestFullscreen().catch((err) => {
        console.error("Error attempting to enter fullscreen mode:", err);
      });
    }
  };

  const handleFullscreenExit = (ref) => {
    // Reset custom styles when exiting fullscreen
    if (ref.current) {
      ref.current.style.width = "";
      ref.current.style.height = "";
      ref.current.style.position = "";
      ref.current.style.top = "";
      ref.current.style.left = "";
      ref.current.style.zIndex = "";
      ref.current.style.backgroundColor = "";
      ref.current.style.objectFit = "";
    }
  };

  // Add an event listener for fullscreen changes
  const useFullscreenListener = (ref) => {
    useEffect(() => {
      const handleFullscreenChange = () => {
        if (!document.fullscreenElement) {
          handleFullscreenExit(ref);
        }
      };

      document.addEventListener("fullscreenchange", handleFullscreenChange);

      return () => {
        document.removeEventListener(
          "fullscreenchange",
          handleFullscreenChange
        );
      };
    }, [ref]);
  };

  const fullName = `${userUpdateData.firstName || ""} ${
    userUpdateData.middleName || ""
  } ${userUpdateData.lastName || ""}`.trim();

  useFullscreenListener(imageRef);
  useFullscreenListener(addressRef);
  useFullscreenListener(photoRef);

  const allDropDownMessages = [
    { id: 1, name: "ID Proof is missing or inappropriate" },
    { id: 2, name: "Address Proof is missing or inappropriate" },
    { id: 3, name: "Photo ID is missing or inappropriate" },
    { id: 4, name: "Address is incomplete or inappropriate" },
    {
      id: 5,
      name: "First Name or Middle name or Last Name is incomplete or inappropriate",
    },
  ];
  const options = allDropDownMessages.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const onChangeHandlerdropdown = (selectedOptions) => {
    setDropdownMessage(selectedOptions);
    if (errorField.dropdownMessage) {
      setErrorField({ ...errorField, dropdownMessage: false });
    }
  };

  const handleSubmit = () => {
    if (dropdownMessage.length > 0) {
      handleDoKyc();
      closeKycModal1();
    } else {
      setErrorField({ ...errorField, dropdownMessage: true });
    }
  };

  const handleback = () => {
    navigate(-1)
  };

  return (
    <React.Fragment>
      <title>Shubhra Viraj | Student KYC Details</title>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="search_wrap">
              <div className="SearchBox"></div>
              <h4 className="Title">
                <Link onClick={handleback}>
                  <i className="fa fa-arrow-left"></i>
                </Link>
                Student KYC Details
              </h4>
            </div>

            <>
              {loader}
              <div className="row mt-3">
                <div className="col-md-12">
                  <div>
                    <br />
                    <h4>Personal Details</h4>
                    <br />
                    <table className="table">
                      <tbody>
                        <tr>
                          <th>Student Name</th>
                          <td>{fullName}</td>
                        </tr>
                        <tr>
                          <th>Mobile Number</th>
                          <td>
                            {userUpdateData.Mobile ||
                              "No Mobile Number Provided"}{" "}
                            /{" "}
                            {userUpdateData.EmergencyContactNo ||
                              "No Emergency Contact No Provided"}{" "}
                          </td>
                        </tr>
                        <tr>
                          <th>Email ID</th>
                          <td>
                            {userUpdateData.EmailID || "No Email ID Provided"}
                          </td>
                        </tr>
                        <tr>
                          <th>Education</th>
                          <td>
                            {userUpdateData.Education ||
                              "No Education Provided"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div>
                    <br />
                    <h4>Address Details</h4>
                    <br />
                    <table className="table">
                      <tr>
                        <th style={{ padding: 5 }}>Present Address</th>
                        <th style={{ padding: 5 }}>Permanent Address</th>
                      </tr>
                      <tr>
                        <td>
                          {userUpdateData.THouseNo || "No House No Provided"},
                          <br />{" "}
                          {userUpdateData.TStreet || "No Street Provided"},
                          <br />{" "}
                          {userUpdateData.TLandmark || "No Landmark Provided"},
                          <br /> {userUpdateData.TState || "No State Provided"},
                          <br /> {userUpdateData.TCity || "No City Provided"},
                          <br />{" "}
                          {userUpdateData.TZipCode || "No Zipcode Provided"}
                        </td>
                        <td>
                          {userUpdateData.PHouseNo || "No House No Provided"},
                          <br />{" "}
                          {userUpdateData.PStreet || "No Street Provided"},
                          <br />{" "}
                          {userUpdateData.PLandmark || "No Landmark Provided"},
                          <br /> {userUpdateData.PState || "No State Provided"},
                          <br /> {userUpdateData.PCity || "No City Provided"},
                          <br />{" "}
                          {userUpdateData.PZipCode || "No Zipcode Provided"}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div>
                  <br />
                  <h4>Documents</h4>
                  <br />
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      justifyContent: "space-between",
                      gap: 150,
                      cursor: "pointer",
                    }}
                  >
                    <Card
                      style={{ height: "280px", width: "280px" }}
                      onClick={() => handleFullscreen(imageRef)}
                    >
                      <CardContent>
                        <b>ID Proof</b>
                      </CardContent>
                      <CardMedia
                        component="img"
                        style={{ height: "210px" }}
                        image={idProof}
                        alt="ID Proof"
                        ref={imageRef}
                      />
                    </Card>

                    <Card
                      style={{ height: "280px", width: "280px" }}
                      onClick={() => handleFullscreen(addressRef)}
                    >
                      <CardContent>
                        <b>Address Proof</b>
                      </CardContent>
                      <CardMedia
                        component="img"
                        style={{ height: "210px" }}
                        image={addressProof}
                        alt="Address Proof"
                        ref={addressRef}
                      />
                    </Card>

                    <Card
                      style={{ height: "280px", width: "280px" }}
                      onClick={() => handleFullscreen(photoRef)}
                    >
                      <CardContent>
                        <b>ID Photo</b>
                      </CardContent>
                      <CardMedia
                        component="img"
                        style={{ height: "210px" }}
                        image={idPhoto}
                        alt="ID Photo"
                        ref={photoRef}
                      />
                    </Card>
                  </div>
                </div>

                <div
                  style={{
                    gap: 50,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: 40,
                  }}
                >
                  {!hideButtons && (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ justifyContent: "space-between", gap: 50 }}
                    >
                      <Button onClick={openKycModal1}>Send for KYC</Button>
                      <Button onClick={openKycModal2}>Complete KYC</Button>
                    </div>
                  )}
                </div>
              </div>
            </>
            <ModalBox
              status={showKycModal1}
              onClose={closeKycModal1}
              size={"sm"}
              title={"Send for KYC"}
              description={
                <div style={{ height: "300px" }}>
                  <p>Student will be requested to submit the details again</p>
                  <div>
                    <div className="col-sm-12 col-form-label">
                      <Select
                        isMulti
                        name="dropdownMessage"
                        options={options}
                        onChange={onChangeHandlerdropdown}
                        value={dropdownMessage}
                        classNamePrefix="react-select"
                        placeholder="Select Comment"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                          }),
                        }}
                      />
                      {errorField.dropdownMessage && (
                        <div className="invalid-feedback">
                          This field is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              }
              customizedFooter={
                <>
                  <Button
                    onClick={handleSubmit}
                    {...submitButtonElement}
                    disabled={dropdownMessage.length === 0}
                  >
                    Submit
                  </Button>
                </>
              }
            />
            <ModalBox
              status={showKycModal2}
              onClose={closeKycModal2}
              title={"Complete KYC"}
              description={
                "Details found to be as expected mark KYC to be completed"
              }
              customizedFooter={
                <>
                  <Button
                    onClick={() => {
                      handleVerifyKyc();
                      closeKycModal2();
                    }}
                    {...submitButtonElement}
                  >
                    Submit
                  </Button>
                </>
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StudentKYCAction;
