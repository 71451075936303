import React, { useState, useEffect } from "react";
import { Button } from "components/Button/Button";
import useFullPageLoader from "hooks/useFullPageLoader";
import * as XLSX from "xlsx";
import useMuiButtonLoader from "hooks/useMuiButtonLoader";
import ReactSelect from "react-select";
import toast from "react-hot-toast";
import ReactDatePicker from "react-datepicker";
import NotFound from "components/not-found/NotFound";
import Pagination from "react-js-pagination";
import { Table } from "react-bootstrap";
import { getAdmissionByAffiliate as fetchAdmissionByAffiliate } from "services/reports/reportController";
import { dateFormat, simpleDateFormat, simpleDateFormatForSearch } from "utils";
import useDebounce from "hooks/useDebounce";
import { useSearchParams } from "react-router-dom";
import FormControl from "@mui/material/FormControl";

const AffiliateStudentCourseAccessReport = () => {
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [searchParam, setSearchParam] = useSearchParams();
  const [startDate, setStartDate] = useState(null);
  // const [limit, setLimit] = useState(10);
  const [limit, setLimit] = useState(10);
  const [endDate, setEndDate] = useState(null);
  const [total, setTotal] = useState(0);
  const [record, setRecord] = useState([]);
  const [price, setPrice] = useState(0);
  const [flag, setFlag] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState(searchParam.get("query") || "");
  const [searchData, setSearchData] = useState({});
  const debounceValue = useDebounce(search, 300);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [selectedAdmissionType, setSelectedAdmissionType] = useState("");
  const [selectedAccessType, setSelectedAccessType] = useState("");

  const [submitButtonElement, setSubmitLoading] = useMuiButtonLoader();
  const [submitButtonElement2, setSubmitLoading2] = useMuiButtonLoader();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  useEffect(() => {
    if (isSearchClicked && startDate && endDate) {
      getAdmissionByAffiliate(activePage, debounceValue, limit, "search");
    }
  }, [activePage, debounceValue, startDate, endDate, isSearchClicked]);

  useEffect(() => {
    setIsSearchClicked(false);
  }, [startDate, endDate]);

  // console.log(limit);

  const getAdmissionByAffiliate = async (
    page = activePage,
    search = debounceValue,
    pageLimit = limit,
  ) => {
    let otherSearch = {};
    for (let [key, value] of Object.entries(searchData)) {
      const bool = key.split("-")?.[1] === "date";
      if (bool) {
        Object.assign(otherSearch, {
          [key.split("-")?.[0]]: simpleDateFormatForSearch(value),
        });
      } else {
        Object.assign(otherSearch, { [key]: value });
      }
    }

    const formattedStartDate = simpleDateFormat(startDate);
    const formattedEndDate = simpleDateFormat(endDate);

    try {
      showLoader();
      const finalData = {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        admissiontype: selectedAdmissionType,
        hasAccess: selectedAccessType,

        search: emailOrPhone,
        page,
        limit: pageLimit,
        ...otherSearch,
      };
      const res = await fetchAdmissionByAffiliate(finalData);
      hideLoader();
      if (res.code === 200) {
        setRecord(res.data.affiliateAdmissions || []);
        setTotal(res.data.count || 0);
        setPrice(res.data.totalBill || 0);
        setSubmitLoading(false);
        setFlag(true);
      } else {
        toast.error(res.message);
        setFlag(false);
        setSubmitLoading(false);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      setSubmitLoading(false);
    }
  };


  const handleLimitChange = (event) => {
    console.log("Event:", event); // Check event object
    const newLimit = parseInt(event.target.value, 10);
    console.log("New Limit:", newLimit); // Check newLimit value
    if (!isNaN(newLimit)) {
      setLimit(newLimit);
      searchParam.set("limit", newLimit);
      setSearchParam(new URLSearchParams(searchParam));
      getAdmissionByAffiliate(activePage, debounceValue, newLimit, "search");
    } else {
      toast.error("Invalid limit value");
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getAdmissionByAffiliate(pageNumber, debounceValue, limit, "search");
  };

  const searchHandler = async (e) => {
    e.preventDefault();
    if (!startDate || !endDate) {
      toast.error("Please Select Start Date and End Date");
      return;
    }
    setIsSearchClicked(true);
    getAdmissionByAffiliate(activePage, debounceValue, limit, "search");
  };

  const downloadExcelHandler = async () => {
    try {
      setSubmitLoading2(true);

      const flag = record.length > 0; // Check if records exist before proceeding

      if (flag) {
        // Prepare the request payload with 'type: "excel"' at the root level
        const finalData = {
          startDate: simpleDateFormat(startDate),
          endDate: simpleDateFormat(endDate),
          search: emailOrPhone,
          admissiontype: selectedAdmissionType,
          // page: activePage,
          // limit: limit,
          hasAccess: selectedAccessType,
          type: "excel",
        };

        // Call the API with the updated payload
        const response = await fetchAdmissionByAffiliate(finalData);

        if (response && response.data) {
          // Format the record data as required for Excel
          const formattedRecord = response.data.affiliateAdmissions.map(
            (entry, i) => {
              return {
                "S.NO": i + 1,
                "STUDENT NAME": `${entry.StudentDetails?.FirstName || ''} ${entry.StudentDetails?.LastName || ''}`, // Provide a default value
                "EMAIL": entry.StudentDetails?.EmailID || "N/A",
                "MOBILE NUMBER": entry.StudentDetails?.Mobile || "N/A",
                "COURSE NAME": entry.CourseData?.CourseTitle || "N/A",
                "AFFILIATE NAME": entry.AffiliateName || "N/A", // Add null check for AffiliateDetails
                "ADMISSION TYPE": entry.AdmissionType || "N/A", // Add null check for AffiliateDetails
                "HAS ACCESS": entry.hasAccess,
              };
            }
          );

          // Create a new Excel workbook and sheet
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(formattedRecord);
          XLSX.utils.book_append_sheet(wb, ws, "AffiliateStudentAdmission");

          // Write the Excel file
          XLSX.writeFile(wb, "MyExcel.xlsx");

          toast.success("Excel file has been downloaded successfully!");
          setSubmitLoading2(false);
        } else {
          toast.error("Failed to download Excel. No data returned.");
          setSubmitLoading2(false);
        }
      } else {
        toast.error("Table data is not found");
        setSubmitLoading2(false);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error("Error occurred while downloading Excel");
      setSubmitLoading2(false);
    }
  };

  return (
    <React.Fragment>
      <title>
        Shubhra Viraj | Affiliate Admission Student Course Access Report
      </title>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="search_wrap">
              <div className="SearchBox"></div>
              <h4 className="Title">
                Affiliate Admission Student Course Access Report
              </h4>
            </div>
            <div className="mt-3">
              <form onSubmit={searchHandler}>
                <div className="row w-100" style={{ '--bs-gutter-x': '0.5rem' }}>
                  <div className={`col-form-label py-0 col-sm-2`}>
                    <ReactDatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className={`form-control`}
                      maxDate={new Date()}
                      showIcon
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Start Date"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      onChangeRaw={(e) => e.preventDefault()}
                    />
                  </div>

                  <div className={`col-form-label py-0 col-sm-2`}>
                    <ReactDatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      className={`form-control`}
                      minDate={startDate}
                      maxDate={new Date()}
                      showIcon
                      dateFormat="dd-MM-yyyy"
                      placeholderText="End Date"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      onChangeRaw={(e) => e.preventDefault()}
                    />
                  </div>

                  <div className="col-sm-2 mui-auto-complete">
                    <ReactSelect
                      className="w-100 react-select"
                      placeholder="Admission Type"
                      options={[
                        { label: "Online", value: "online" },
                        { label: "Offline", value: "offline" },
                      ]}
                      isSearchable={true}
                      onChange={(data) =>
                        setSelectedAdmissionType(data ? data.value : "")
                      }
                    />
                  </div>

                  <div className="col-sm-2 mui-auto-complete">
                    <ReactSelect
                      className="w-100 react-select"
                      placeholder="Access Type"
                      options={[
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" },
                      ]}
                      isSearchable={true}
                      onChange={(data) =>
                        setSelectedAccessType(data ? data.value : "")
                      }
                    />
                  </div>

                  <div className="col-sm-2">
                    <input
                      type="text"
                      placeholder="Email/Mobile"
                      className="form-control"
                      value={emailOrPhone}
                      onChange={(e) => setEmailOrPhone(e.target.value)}
                    />
                  </div>

                  <div className="col-sm-2">
                    <Button type="submit" {...submitButtonElement}>
                      Search
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            {loader}
            <hr className="border border-danger" />
          </div>
          {flag && (
            <div className="Small-Wrapper my-3">
              <div className="search_wrap">
                <div className="SearchBox">
                  <div className="div_wrap"></div>
                  <div className="div_no_wrap mx-1 row"></div>
                  <label htmlFor="rows-select" className="form-label-dp">
                    No of rows:
                  </label>
                  <FormControl sx={{ m: 1, minWidth: 40 }} size="small">
                    <select
                      className="form-control-dp"
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={limit}
                      onChange={handleLimitChange} // Call handleLimitChange when the value changes
                    >
                      {[10, 20, 40, 80, 100].map((num) => (
                        <option value={num} key={num}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                  <Button
                    className="mx-2"
                    onClick={downloadExcelHandler}
                    {...submitButtonElement2}
                  >
                    Export To Excel
                  </Button>
                </div>
                <h4 className="Title">
                  Affiliate Admission Student Course Access (Total Count:{" "}
                  {total})
                </h4>
              </div>

              <div className="TableList overflow-auto table-responsive">
                {loader}
                <Table bordered className="table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Student Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Course Name</th>
                      <th>Affiliate Name</th>
                      <th>Admission Type</th>
                      <th>Has Access</th>

                    </tr>
                  </thead>
                  <tbody>
                    {record && record.length > 0 ? (
                      record.map((data, i) => (
                        <tr key={i}>
                          <td>{i + 1 + (activePage - 1) * limit}</td>
                          <td>{`${data.StudentDetails?.FirstName || ''} ${data.StudentDetails?.LastName || ''}`}</td>
                          <td>{data.StudentDetails?.EmailID || "---"}</td>
                          <td>{data.StudentDetails?.Mobile || "---"}</td>
                          <td style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{data.CourseData?.CourseTitle}</td>
                          <td style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{data.AffiliateName || ""}</td>
                          <td>{data.AdmissionType || "Online"}</td>
                          <td>{data.hasAccess}</td>
                        </tr>
                      ))
                    ) : (
                      <NotFound />
                    )}
                  </tbody>
                </Table>
              </div>
              {total > 0 && (
                <div className="Pagination">
                  <Pagination
                    activePage={activePage}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    itemsCountPerPage={10}
                    totalItemsCount={total}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AffiliateStudentCourseAccessReport;
