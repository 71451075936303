import { decryptData, encryptData } from "config/config";
import { instance as axios, setMultiPartHeader } from "config/axiosInstance";
import { isLoggedIn } from "utils";

export const getBanners = async (data) => {
  return decryptData(
    await axios.get(
      `/banners?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const addBanner = async (data) => {
  return decryptData(
    await axios.post(`/banners-create`, data, setMultiPartHeader())
  );
};

export const updateBanner = async (data) => {
  return decryptData(
    await axios.post(`/banners-edit`, data, setMultiPartHeader())
  );
};

export const deleteBanner = async (data) => {
  return decryptData(
    await axios.get(
      `/banners-delete?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const updateBannerStatus = async (data) => {
  return decryptData(
    await axios.get(
      `/banners-status?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getSingleBannerById = async (data) => {
  return decryptData(
    await axios.get(
      `/banners-view?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getTeacherDisplayOrderList = async (data) => {
  return decryptData(
    await axios.get(
      `teachers-list?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getupcomingCoursesExamUniqueTypes = async (data) => {
  return decryptData(
    await axios.get(
      `/upcoming-course-exam-unique-examtypes
?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const getUpcomingExamCoursesTypeID = async (data) => {
  return decryptData(
    await axios.get(
      `/upcoming-exam-courses-by-examtypeid?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const updateTeacherDisplayorder = async (data) => {
  return decryptData(
    await axios.post(`edit-teacher-display-order`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};
export const updateUpcomingCourseDisplayOrder = async (data) => {
  return decryptData(
    await axios.post(`/edit-upcoming-courses-display-order`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};

export const changeshowonwebsite = async (data) => {
  return decryptData(
    await axios.get(
      `/teacher-display-order-onwebsite?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
