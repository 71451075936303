import { decryptData, encryptData,failResponse,errorResponse } from "config/config";
import { masterInstance as axios } from "config/axiosInstance";
import { isLoggedIn } from "utils";

// ---------------- master data controllers ---------------------//
export const getSubjects = async (data) => {
  return decryptData(
    await axios.get(
      `/subjects?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// master subjects for lecture
export const getMasterSubjects = async () => {
  return decryptData(
    await axios.get(
      `/subject-master?reqData=${encryptData({
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getMasterSubSubjects = async (data) => {
  return decryptData(
    await axios.get(
      `/sub-subject-master?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getTeachers = async (data) => {
  return decryptData(
    await axios.get(
      `/teachers?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getPapers = async (data) => {
  return decryptData(
    await axios.get(
      `/papers?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getCenters = async (data) => {
  return decryptData(
    await axios.get(
      `/centers?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getCentersForAddCenter = async (data) => {
  return decryptData(
    await axios.get(
      `/centerpage?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getBlogsCategory = async (data) => {
  return decryptData(
    await axios.get(
      `/blog-category?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getCourseAdmin = async () => {
  return decryptData(
    await axios.get(
      `/course-admin?reqData=${encryptData({
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getCourseVertical = async () => {
  return decryptData(
    await axios.get(
      `/course-verticles?reqData=${encryptData({
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getCourseCoordinator = async () => {
  return decryptData(
    await axios.get(
      `/coordinators?reqData=${encryptData({
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getCourses = async (data = {}) => {
  return decryptData(
    await axios.get(
      `/courses?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getCourseFor = async () => {
  return decryptData(
    await axios.get(
      `/courses-for?reqData=${encryptData({
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getCourseCategory = async () => {
  return decryptData(
    await axios.get(
      `/course-categories?reqData=${encryptData({
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getCourseSubCategory = async (data = {}) => {
  return decryptData(
    await axios.get(
      `/course-subcategories?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getMasterTax = async () => {
  return decryptData(
    await axios.get(
      `/gst?reqData=${encryptData({
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getLectures = async (data) => {
  return decryptData(
    await axios.get(
      `/lectures?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getBillingCourses = async (data = {}) => {
  return decryptData(
    await axios.get(
      `/billing-course?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getUserTypes = async () => {
  return decryptData(
    await axios.get(
      `/user-types?reqData=${encryptData({
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const getTestTypes = async () => {
  return decryptData(
    await axios.get(
      `/test-types?reqData=${encryptData({
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getExamTypes = async () => {
  return decryptData(
    await axios.get(
      `/exam-type?reqData=${encryptData({
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getStreams = async () => {
  return decryptData(
    await axios.get(
      `/stream?reqData=${encryptData({
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getMasterModules = async (data = {}) => {
  return decryptData(
    await axios.get(
      `/video/bank/module/list?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getVdocipherVideos = async (data) => {
  return decryptData(
    await axios.get(
      `/vdo-cipher-videos?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const getMasterLanguages = async () => {
  return decryptData(
    await axios.get(
      `/course/verticle/language?reqData=${encryptData({
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// get youtube master playlist
export const getMasterYoutubePlaylist = async () => {
  return decryptData(
    await axios.get(
      `/youtube/playlist?reqData=${encryptData({
        access_token: isLoggedIn(),
      })}`
    )
  );
};

// get youtube master list
export const getMasterYoutubeList = async (data = {}) => {
  return decryptData(
    await axios.get(
      `/youtube/video?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};


export const getSignedUrl = async (data) => {
  try {
    const res = await decryptData(
      await axios.get(
        `/pdf/signed/url?reqData=${encryptData({
          ...data,
          access_token: isLoggedIn(),
        })}`
      )
    );
    if (res.code === 200) {
      return res.data.psfUrl;
    } else {
      failResponse(res);
      return "";
    }
  } catch (err) {
    errorResponse(err);
  }
};

export const getAffiliates = async (data = {}) => {
  return decryptData(
    await axios.get(
      `/affiliates-list?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};




