import NotFound from "components/not-found/NotFound";
import useFullPageLoader from "hooks/useFullPageLoader";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import ReactSelect from "react-select";
import { Button } from "components/Button/Button";
import {
  getupcomingCoursesExamUniqueTypes,
  getUpcomingExamCoursesTypeID,
  updateUpcomingCourseDisplayOrder,
} from "services/website/bannersController";
import useMuiButtonLoader from "hooks/useMuiButtonLoader";
import { Table } from "react-bootstrap";

function UpcomingExamAndCourseDisplayOrder() {
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  const [record, setRecord] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [submitButtonElement, setSubmitLoading] = useMuiButtonLoader();
  const [selectedExamType, setSelectedExamType] = useState(null); // State to store selected exam type

  const [editedDisplayOrder, setEditedDisplayOrder] = useState({});
  const [examTypes, setExamTypes] = useState([]);


  useEffect(() => {
    // Function to fetch exam types
    const fetchExamTypes = async () => {
      try {
        const res = await getupcomingCoursesExamUniqueTypes();
        if (res.code === 200) {
          const formattedData = res.data.examTypes.map((exam) => ({
            label: exam.ExamTypeName,
            value: exam.examtypeid,
          }));
          setExamTypes(formattedData);
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };

    fetchExamTypes();
  }, []);

  const handleSearch = async () => {
    if (!selectedExamType) {
      toast.error("Please select an exam type.");
      return;
    }

    showLoader(); // Show loader while fetching data
    try {
      const res = await getUpcomingExamCoursesTypeID({
        examtypeid: selectedExamType.value,
      });
      if (res.code === 200) {
        setRecord(res.data.upcomingExamCourse); // Update record with API response
        setTotal(res.data.upcomingExamCourse.length); // Update total for pagination
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      hideLoader(); // Hide loader after fetching data
    }
  };

  // Function to handle display order submission
  const handleSubmitDisplayOrder = async (
    billingCourseId,
    examTypeId,
    displayOrderValue
  ) => {
    if (displayOrderValue < 0) {
      toast.error("Value must be a positive number."); // Notify if negative
      return;
    }

    try {
      setSubmitLoading(true);
      const res = await updateUpcomingCourseDisplayOrder({
        billingcourseid: billingCourseId, // Pass course ID
        examtypeid: examTypeId, // Pass exam type ID
        displayorder: displayOrderValue, // Pass display order value
      });

      if (res.code === 200) {
        toast.success(res.message);
        setEditedDisplayOrder({
          ...editedDisplayOrder,
          [billingCourseId]: undefined, // Clear edited order for this course
        });
        // Optionally, re-fetch the updated data to show the changes in the UI
        await handleSearch();
      } else {
        toast.error(res.message); // Display error message from the API
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message); // Handle error response
    } finally {
      setSubmitLoading(false); // Reset loading state
    }
  };

  const handleDisplayOrderChange = (billingCourseId, value) => {
    const sanitizedValue = Math.max(0, value); // Ensure value is not less than 0

    setEditedDisplayOrder((prevOrders) => ({
      ...prevOrders,
      [billingCourseId]: sanitizedValue, // Update the display order for the specific course
    }));
  };




  return (
    <React.Fragment>
      <title>Shubhra Viraj | Teacher Display Order</title>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="search_wrap">
              <div className="SearchBox"></div>
              <h4 className="Title">
                Upcoming&nbsp;Exam&nbsp;and&nbsp;Courses&nbsp;Display&nbsp;Order
              </h4>
            </div>
            <div className=" d-flex col-sm-6 mt-4">
              <ReactSelect
                className="w-100 react-select"
                placeholder="Select Exam Type"
                isSearchable={true}
                options={examTypes}
                onChange={setSelectedExamType}
              />
              <div className="col-sm-1 mx-4">
                <Button type="button" onClick={handleSearch}>
                  Search
                </Button>
              </div>
            </div>
            <div className="TableList overflow-auto table-responsive">
              {loader}
              <Table bordered className="table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Course Name</th>
                    <th>Display Order</th>
                  </tr>
                </thead>
                <tbody>
                  {record && record.length > 0 ? (
                    record.map(({ BillingCourse, displayorder }, i) => (
                      <tr key={BillingCourse.id}>
                        {" "}
                        <td>{++i + (activePage - 1) * 10}</td>
                        <td>{BillingCourse.CourseTitle}</td>{" "}
                        
                        <td>
                          <input
                            type="number"
                            min="0" // Prevents negative numbers
                            value={
                              editedDisplayOrder[BillingCourse.id] !== undefined
                                ? editedDisplayOrder[BillingCourse.id]
                                : displayorder // Show current display order or empty string if null
                            }
                            onChange={(e) =>
                              handleDisplayOrderChange(
                                BillingCourse.id, // Change to BillingCourse.id
                                e.target.value
                              )
                            }
                            style={{
                              width: "73px",
                              height: "31px",
                              textAlign: "center",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              boxSizing: "border-box",
                              outline: "none",
                            }}
                          />
                          <Button
                            style={{ marginLeft: "20px" }}
                            onClick={() =>
                              handleSubmitDisplayOrder(
                                BillingCourse.id, // Pass the BillingCourse.id correctly
                                selectedExamType?.value, // Pass the selected exam type
                                editedDisplayOrder[BillingCourse.id] ||
                                  displayorder // Submit the edited display order or fallback
                              )
                            }
                          >
                            Submit
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NotFound cols={12} />
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UpcomingExamAndCourseDisplayOrder;
