import { decryptData, encryptData } from "config/config";
import { instance as axios, setMultiPartHeader } from "config/axiosInstance";
import { isLoggedIn } from "utils";

export const getPGMasterList = async (data) => {
  return decryptData(
    await axios.get(
      `/get-pg-master-list?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const getPGMasterView = async (data) => {
  return decryptData(
    await axios.get(
      `/get-pg-master-view?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const getAffiliatePGList = async (data) => {
  return decryptData(
    await axios.get(
      `/get-affiliate-pg-list?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};
export const linkPGCompany = async (data) => {
  return decryptData(
    await axios.get(
      `/link-pg-company-name?reqData=${encryptData({
        ...data,
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const updatePGMasterView = async (data) => {
  return decryptData(
    await axios.post(`/pg-master-edit`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};
export const addPGMasterView = async (data) => {
  return decryptData(
    await axios.post(`/pg-master-add`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};
export const deletePGMasterView = async (data) => {
  return decryptData(
    await axios.post(`/pg-master-delete`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};