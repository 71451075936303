import { Button } from "components/Button/Button";
import NotFound from "components/not-found/NotFound";
import useFullPageLoader from "hooks/useFullPageLoader";
import useMuiButtonLoader from "hooks/useMuiButtonLoader";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import Pagination from "react-js-pagination";
import { Input } from "components/Form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "components/Button/IconButton";
import ModalBox from "components/modal/ModalBox"; // Importing the Modal component
import { getCoursetestforStudents } from "services/student/studentController";
import { useSearchParams } from "react-router-dom";
import { dateFormat } from "utils";

const StudentCourseTestReport = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  let search = searchParam.get("query") || "";

  const [submitButtonElement, setSubmitLoading] = useMuiButtonLoader();
  const [userMobile, setUserMobile] = useState(search || "");
  const [userData, setUserData] = useState({});
  const [record, setRecord] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Search courses by student email
  const searchHandler = async (e) => {
    e.preventDefault();
    if (!userMobile) {
      toast.error("Please Enter User Phone number");
    } else {
      setSubmitLoading(true);
      try {
        showLoader();
        searchParam.set("query", userMobile);
        setSearchParam(searchParam);
        const finalData = {
          search: userMobile,
          page: activePage,
        };
        const res = await getCoursetestforStudents(finalData);

        hideLoader();
        if (res.code === 200) {
          setSubmitLoading(false);
          setRecord(res.data.studentcoursestests || []);
          setTotal(res.data.count || 0);
          setUserData(res.data.userData || {});
        } else if(res.code === 100){
          toast.error("No records found")
          setSubmitLoading(false);
        }
        else {
          setRecord([]);
          setTotal(0);
          setUserData({});
          toast.error(res.message);
          setSubmitLoading(false);
        }
        if (res.data.count == 0) {
          toast.error("No records found")
          setRecord([]);
          setTotal(0);
          setUserData({});
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || err.message);
        setSubmitLoading(false);
      }
    }
  };

  const getCourses = async () => {
    if (!userMobile) {
      toast.error("Please Enter User Email");
    } else {
      try {
        showLoader();
        const finalData = {
          search: userMobile,
          page: activePage,
        };
        const res = await getCoursetestforStudents(finalData);

        hideLoader();
        if (res.code === 200) {
          setRecord(res.data.studentcoursestests || []);
          setTotal(res.data.count || 0);
          setUserData(res.data.userData || {});
        } else {
          toast.error(res.message);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || err.message);
      }
    }
  };

  // Handle Pagination
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  React.useEffect(() => {
    hideLoader();
    userMobile && getCourses();
    // eslint-disable-next-line
  }, [activePage]);

  return (
    <React.Fragment>
      <title>Shubhra Viraj | Student Course Test Report</title>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="search_wrap">
              <div className="SearchBox"></div>
              <h4 className="Title">Student Course Test Report </h4>
            </div>
            <div className=" mt-3">
              <form onSubmit={searchHandler}>
                <div className="row w-100">
                  <div className={`col-form-label py-0 col-sm-7`}>
                    <Input
                      className={"form-control"}
                      placeholder="Enter User Mobile Number"
                      value={userMobile}
                      onChange={({ target }) => setUserMobile(target.value)}
                      type="text"
                    />
                  </div>

                  <div className="col-sm-5 d-flex justify-content-between">
                    <Button type="submit" {...submitButtonElement}>
                      Search
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            <hr className="border border-danger" />
            {loader}
            <div className="row mt-3">
              <div className="col-sm-12">
                <div className="TableList overflow-auto table-responsive p-0">
                Total Records: {total}
                <br />
                <br />
                  <table className="position-relative">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Course Name</th>
                        <th>Test Title</th>
                        <th>Test Type</th>
                        <th>Uploaded Date</th>
                        <th>Evaluation Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {record && record.length > 0 ? (
                        record.map(
                          (
                            { CourseName, TestTitle, TestType, UploadedOn, EvaluationStatus },
                            i
                          ) => (
                            <tr key={i}>
                              <td style={{ textAlign: "left" }}>
                                {++i + (activePage - 1) * 20}
                              </td>
                              <td>{CourseName}</td>
                              <td>{TestTitle}</td>
                              <td>{TestType}</td>
                              <td>{dateFormat(UploadedOn)}</td>
                              <td>{EvaluationStatus}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <NotFound cols={12} />
                      )}
                    </tbody>
                  </table>
                </div>
                {total > 10 ? (
                  <div className="Pagination">
                    <Pagination
                      activePage={activePage}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      itemsCountPerPage={10}
                      totalItemsCount={total}
                      pageRangeDisplayed={5}
                      onChange={(page) => handlePageChange(page)}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StudentCourseTestReport;
