import React, { useState, useEffect } from "react";
import { Button } from "components/Button/Button";
import useFullPageLoader from "hooks/useFullPageLoader";
import * as XLSX from "xlsx";
import useMuiButtonLoader from "hooks/useMuiButtonLoader";
import ReactSelect from "react-select";
import toast from "react-hot-toast";
import ReactDatePicker from "react-datepicker";
import NotFound from "components/not-found/NotFound";
import Pagination from "react-js-pagination";
import { Table } from "react-bootstrap";
import { getLectureAttemptLog as fetchLectureAttemptLog } from "services/reports/reportController";
import { dateFormat, simpleDateFormat, simpleDateFormatForSearch } from "utils";
import useDebounce from "hooks/useDebounce";
import { useSearchParams } from "react-router-dom";
import FormControl from "@mui/material/FormControl";

const LectureAttemptLog = () => {
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [searchParam, setSearchParam] = useSearchParams();
  const [startDate, setStartDate] = useState(null);
  // const [limit, setLimit] = useState(10);
  const [limit, setLimit] = useState(10);
  const [endDate, setEndDate] = useState(null);
  const [total, setTotal] = useState(0);
  const [record, setRecord] = useState([]);
  const [price, setPrice] = useState(0);
  const [flag, setFlag] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState(searchParam.get("query") || "");
  const [searchData, setSearchData] = useState({});
  const debounceValue = useDebounce(search, 300);
  const [isSearchClicked, setIsSearchClicked] = useState(false);

  const [submitButtonElement, setSubmitLoading] = useMuiButtonLoader();
  const [submitButtonElement2, setSubmitLoading2] = useMuiButtonLoader();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  useEffect(() => {
    if (isSearchClicked && startDate && endDate) {
      getLectureAttemptLog(activePage, debounceValue, limit, "search");
    }
  }, [activePage, debounceValue, startDate, endDate, isSearchClicked]);

  useEffect(() => {
    setIsSearchClicked(false);
  }, [startDate, endDate]);

  // console.log(limit);

  const getLectureAttemptLog = async (
    page = activePage,
    search = debounceValue,
    pageLimit = limit,
    type
  ) => {
    let otherSearch = {};
    for (let [key, value] of Object.entries(searchData)) {
      const bool = key.split("-")?.[1] === "date";
      if (bool) {
        Object.assign(otherSearch, {
          [key.split("-")?.[0]]: simpleDateFormatForSearch(value),
        });
      } else {
        Object.assign(otherSearch, { [key]: value });
      }
    }
    if (type === "search") {
      setSubmitLoading(true);
    }

    const formattedStartDate = simpleDateFormat(startDate);
    const formattedEndDate = simpleDateFormat(endDate);

    try {
      showLoader();
      const finalData = {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        search: emailOrPhone,
        page,
        limit: pageLimit,
        ...otherSearch,
      };
      const res = await fetchLectureAttemptLog(finalData);
      hideLoader();
      if (res.code === 200) {
        setRecord(res.data.lectureattemptlog.rows || []);
        setTotal(res.data.lectureattemptlog.count || 0);
        setPrice(res.data.totalBill || 0);
        setSubmitLoading(false);
        setFlag(true);
      } else {
        toast.error(res.message);
        setFlag(false);
        setSubmitLoading(false);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      setSubmitLoading(false);
    }
  };

  // const handleLimitChange = (event) => {
  //   console.log("Event:", event); // Check event object
  //   const newLimit = parseInt(event.target.value, 10);
  //   console.log("New Limit:", newLimit); // Check newLimit value
  //   if (!isNaN(newLimit)) {
  //     setLimit(newLimit);
  //     searchParam.set("limit", newLimit);
  //     setSearchParam(searchParam);
  //     getLectureAttemptLog(activePage, debounceValue, newLimit);
  //   } else {
  //     toast.error("Invalid limit value");
  //   }
  // };

  const handleLimitChange = (event) => {
    console.log("Event:", event); // Check event object
    const newLimit = parseInt(event.target.value, 10);
    console.log("New Limit:", newLimit); // Check newLimit value
    if (!isNaN(newLimit)) {
      setLimit(newLimit);
      searchParam.set("limit", newLimit);
      setSearchParam(new URLSearchParams(searchParam));
      getLectureAttemptLog(activePage, debounceValue, newLimit, "search");
    } else {
      toast.error("Invalid limit value");
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getLectureAttemptLog(pageNumber, debounceValue, limit, "search");
  };

  const searchHandler = async (e) => {
    e.preventDefault();
    if (!startDate || !endDate) {
      toast.error("Please Select Start Date and End Date");
      return;
    }
    setIsSearchClicked(true);
    getLectureAttemptLog(activePage, debounceValue, limit, "search");
  };

  const downloadExcelHandler = () => {
    if (flag) {
      const formattedRecord = record.map((entry) => {
        if (entry.addedon) {
          return { ...entry, addedon: dateFormat(entry.addedon) };
        }
        return entry;
      });
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(formattedRecord);
      XLSX.utils.book_append_sheet(wb, ws, "LectureAttemptLog");
      XLSX.writeFile(wb, "MyExcel.xlsx");
    } else {
      toast.error("Table data is not found");
    }
  };

  return (
    <React.Fragment>
      <title>Shubhra Viraj | Lecture Attempt Log</title>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="search_wrap">
              <div className="SearchBox"></div>
              <h4 className="Title">Lecture Attempt Log</h4>
            </div>
            <div className="mt-3">
              <form onSubmit={searchHandler}>
                <div className="row w-100">
                  <div className={`col-form-label py-0 col-sm-3`}>
                    <ReactDatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className={`form-control`}
                      maxDate={new Date()}
                      showIcon
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Select Start Date"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      onChangeRaw={(e) => e.preventDefault()}
                    />
                  </div>

                  <div className={`col-form-label py-0 col-sm-3`}>
                    <ReactDatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      className={`form-control`}
                      minDate={startDate}
                      maxDate={new Date()}
                      showIcon
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Select End Date"
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      onChangeRaw={(e) => e.preventDefault()}
                    />
                  </div>

                  <div className="col-sm-4">
                    <input
                      type="text"
                      placeholder="Enter Email/Phone Number"
                      className="form-control"
                      value={emailOrPhone}
                      onChange={(e) => setEmailOrPhone(e.target.value)}
                    />
                  </div>

                  <div className="col-sm-2">
                    <Button type="submit" {...submitButtonElement}>
                      Search
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            {loader}
            <hr className="border border-danger" />
          </div>
          {flag && (
            <div className="Small-Wrapper my-3">
              <div className="search_wrap">
                <div className="SearchBox">
                  <div className="div_wrap"></div>
                  <div className="div_no_wrap mx-1 row"></div>
                  <label htmlFor="rows-select" className="form-label-dp">
                    No of rows:
                  </label>
                  <FormControl sx={{ m: 1, minWidth: 40 }} size="small">
                    <select
                      className="form-control-dp"
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={limit}
                      onChange={handleLimitChange} // Call handleLimitChange when the value changes
                    >
                      {[10, 20, 40, 80, 100].map((num) => (
                        <option value={num} key={num}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                  <Button
                    className="mx-2"
                    onClick={downloadExcelHandler}
                    {...submitButtonElement2}
                  >
                    Export To Excel
                  </Button>
                </div>
                <h4 className="Title">
                  Lecture Attempt (Total Count: {total})
                </h4>
              </div>

              <div className="TableList overflow-auto table-responsive">
                {loader}
                <Table bordered className="table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Student Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Course Name</th>
                      <th>Lecture Title</th>
                      <th>Platform</th>
                      <th>Viewed On</th>
                    </tr>
                  </thead>
                  <tbody>
                    {record && record.length > 0 ? (
                      record.map((data, i) => (
                        <tr key={i}>
                          <td>{i + 1 + (activePage - 1) * limit}</td>
                          <td>
                            {`${data.FirstName || "--"} ${
                              data.LastName || "--"
                            }`.trim()}
                          </td>
                          <td>{data.EmailID || "---"}</td>
                          <td>{data.Mobile || "---"}</td>
                          <td>{data.CourseTitle}</td>
                          <td>{data.LectureTitle}</td>
                          <td>{data.platform}</td>
                          <td>{dateFormat(data.addedon)}</td>
                        </tr>
                      ))
                    ) : (
                      <NotFound  />
                    )}
                  </tbody>
                </Table>
              </div>
              {total > 0 && (
                <div className="Pagination">
                  <Pagination
                    activePage={activePage}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    itemsCountPerPage={10}
                    totalItemsCount={total}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default LectureAttemptLog;
