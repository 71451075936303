import { Button } from "components/Button/Button";
import NotFound from "components/not-found/NotFound";
import useFullPageLoader from "hooks/useFullPageLoader";
import useMuiButtonLoader from "hooks/useMuiButtonLoader";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import Pagination from "react-js-pagination";
import { Input } from "components/Form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "components/Button/IconButton";
import ModalBox from "components/modal/ModalBox"; // Importing the Modal component
import {
  getCourseforStudents,
  getWatchingHoursforStudents,
} from "services/student/studentController"; // Importing the APIs
import { useSearchParams } from "react-router-dom";

const StudentWatchingHours = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  let search = searchParam.get("query") || "";

  const [submitButtonElement, setSubmitLoading] = useMuiButtonLoader();
  const [userEmail, setUserEmail] = useState(search || "");
  const [modalLoader, setModalLoader] = useState(false);

  const [userID, setUserID] = useState({});

  const [record, setRecord] = useState([]);
  const [total, setTotal] = useState(0);

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [watchingHours, setWatchingHours] = useState(null);

  // Search courses by student email
  const searchHandler = async (e) => {
    e.preventDefault();
    if (!userEmail) {
      toast.error("Please Enter User Email or Phone number");
    } else {
      setSubmitLoading(true);
      try {
        showLoader();
        searchParam.set("query", userEmail);
        searchParam.set("page", 1); // Reset to first page on new search
        setSearchParam(searchParam);
        const finalData = {
          search: userEmail,
          page: 1,
        };
        const res = await getCourseforStudents(finalData);

        hideLoader();
        if (res.code === 200) {
          setSubmitLoading(false);
          setRecord(res.data.studentcourses.rows || []);
          setTotal(res.data.studentcourses.count || 0);
          setUserID(res.data.userid || {});
        } else {
          setRecord([]);
          setTotal(0);
          setUserID({});
          toast.error(res.message);
          setSubmitLoading(false);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || err.message);
        setSubmitLoading(false);
      }
    }
  };

  const fetchWatchingHours = async (userid, courseid) => {
    try {
      setModalLoader(true);
      const finalData = {
        userid: userID,
        courseid: courseid,
      };
      const res = await getWatchingHoursforStudents(finalData);

      if (res.code === 200) {
        setWatchingHours(res.data.result.data.watchinghours);
      } else {
        toast.error(res.message);
      }
      setModalLoader(false);
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      setModalLoader(false);
    }
  };

  const fetchCourses = async (page) => {
    if (!userEmail) {
      toast.error("Please Enter User Email");
    } else {
      try {
        showLoader();
        const finalData = {
          search: userEmail,
          page: page,
        };
        const res = await getCourseforStudents(finalData);

        hideLoader();
        if (res.code === 200) {
          setRecord(res.data.studentcourses.rows || []);
          setTotal(res.data.studentcourses.count || 0);
          setUserID(res.data.userid || {});
        } else {
          toast.error(res.message);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || err.message);
      }
    }
  };

  // Handle pagination
  const handlePageChange = (pageNumber) => {
    searchParam.set("page", pageNumber);
    setSearchParam(searchParam);
    fetchCourses(pageNumber); // Fetch courses for the new page
  };

  React.useEffect(() => {
    hideLoader();
    userEmail && fetchCourses(activePage);
    // eslint-disable-next-line
  }, [activePage]);

  return (
    <React.Fragment>
      <title>Shubhra Viraj | Student Watching Hours</title>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="search_wrap">
              <div className="SearchBox"></div>
              <h4 className="Title">Student Watching Hours</h4>
            </div>
            <div className=" mt-3">
              <form onSubmit={searchHandler}>
                <div className="row w-100">
                  <div className={`col-form-label py-0 col-sm-7`}>
                    <Input
                      className={"form-control"}
                      placeholder="Enter User Email Address or Phone"
                      value={userEmail}
                      onChange={({ target }) => setUserEmail(target.value)}
                      type="text"
                    />
                  </div>

                  <div className="col-sm-5 d-flex justify-content-between">
                    <Button type="submit" {...submitButtonElement}>
                      Search
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            <hr className="border border-danger" />
            {loader}
            <div className="row mt-3">
              <div className="col-sm-12">
                <div className="TableList overflow-auto table-responsive p-0">
                  Total Records: {total}
                  <br />
                  <br />
                  <table className="position-relative">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Course Name</th>
                        <th>Watched History</th>
                      </tr>
                    </thead>
                    <tbody>
                      {record && record.length > 0 ? (
                        record.map(({ courseid, CourseName, userid }, i) => (
                          <tr key={i}>
                            <td style={{ textAlign: "left" }}>
                              {++i + (activePage - 1) * 10}
                            </td>
                            <td style={{ textAlign: "left" }}>{CourseName}</td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <IconButton
                                  aria-label="view"
                                  className="px-2 py-0"
                                  onClick={() => {
                                    fetchWatchingHours(userid, courseid);
                                    setIsModalOpen(true);
                                  }}
                                >
                                  <VisibilityIcon sx={{ color: "#4971B2" }} />
                                </IconButton>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NotFound cols={3} />
                      )}
                    </tbody>
                  </table>
                </div>
                {total > 10 ? (
                  <div className="Pagination">
                    <Pagination
                      activePage={activePage}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      itemsCountPerPage={10}
                      totalItemsCount={total}
                      pageRangeDisplayed={5}
                      onChange={(page) => handlePageChange(page)}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalBox
        status={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        customizedFooter={true}
        Close={true}
        title={"Watch History Details"}
      >
        <div className="TableList overflow-auto table-responsive p-0">
          {modalLoader ? (
            // Show loader inside the modal
            <div className="loader">Loading...</div>
          ) : (
            <table>
              <tbody>
                {watchingHours ? (
                  <>
                    <tr>
                      <th>Total Watch Hours</th>
                      <td>
                        :{" "}
                        {`${watchingHours.hours}h ${watchingHours.minutes}m ${watchingHours.seconds}s`}
                      </td>
                    </tr>
                    {/* Add more rows here if needed */}
                  </>
                ) : (
                  <tr>
                    <td colSpan="2">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </ModalBox>
    </React.Fragment>
  );
};

export default StudentWatchingHours;
