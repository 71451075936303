
// import ModalBox from "../../components/modal/ModalBox";
// import NotFound from "components/not-found/NotFound";
// import React, { useState, useEffect } from "react";
// import { toast } from "react-hot-toast";
// import { useSearchParams } from "react-router-dom";
// import { Button } from "components/Button/Button";
// import useFullPageLoader from "hooks/useFullPageLoader";
// import Select from "react-select";
// import { Typography } from "@mui/material";
// import { useAdminAuth } from "context/context";
// import { getAffiliatePGList } from "services/PGController/PGController"; // Adjust the import path as needed
// import { getPGMasterList } from "services/PGController/PGController"; // Import the API function
// import { Table } from "react-bootstrap";

// const AffiliatePG = () => {
//   const [searchParam, setSearchParam] = useSearchParams();
//   const [record, setRecord] = useState([]);
//   const [pgOptions, setPgOptions] = useState([]);
//   const [total, setTotal] = useState(0);
//   const [loader, showLoader, hideLoader] = useFullPageLoader();
//   const [showModal, setShowModal] = useState(false);
//   const [selectedRecord, setSelectedRecord] = useState(null);
//   const [selectedCompany, setSelectedCompany] = useState(null);

//   const { roles } = useAdminAuth();

//   // Fetch PG Master list options
//   const fetchPGMasterList = async () => {
//     try {
//       const response = await getPGMasterList();
//       if (response.code === 200) {
//         const options = response.data.pgmasterlist.map((pg) => ({
//           value: pg.CompanyName,
//           label: pg.CompanyName
//         }));
//         setPgOptions(options);
//       } else {
//         toast.error(response.message || "Failed to fetch PG master list");
//       }
//     } catch (error) {
//       toast.error("An error occurred while fetching PG master list");
//     }
//   };

//   // Fetch affiliate PG list and PG master list when the component mounts
//   useEffect(() => {
//     fetchAffiliatePGList();
//     fetchPGMasterList();
//   }, []);

//   const fetchAffiliatePGList = async () => {
//     try {
//       showLoader();
//       const response = await getAffiliatePGList(); // Adjust if needed
//       if (response.code === 200) {
//         setRecord(response.data.affiliatepglist);
//         setTotal(response.data.affiliatepglist.length);
//       } else {
//         toast.error(response.message || "Failed to fetch data");
//       }
//     } catch (error) {
//       toast.error("An error occurred while fetching data");
//     } finally {
//       hideLoader();
//     }
//   };

//   const handleLinkClick = (record) => {
//     console.log("Record clicked:", record); // Log the record data
//     setSelectedRecord(record);
//     setSelectedCompany(
//       pgOptions.find(option => option.value === record.affiliatepgs[0]?.pgmaster.CompanyName) || null
//     );
//     setShowModal(true);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//     setSelectedRecord(null);
//     setSelectedCompany(null);
//   };

//   return (
//     <React.Fragment>
//       <title>Shubhra Viraj | Billing Courses</title>
//       <div className="">
//         <div className="WrapperBox">
//           <div className="Small-Wrapper">
//             <div className="search_wrap">
//               <div className="SearchBox"></div>
//               <h4 className="Title">Affiliate PG List</h4>
//             </div>
//             <div className="TableList overflow-auto table-responsive">
//               {loader}
//               <Table bordered className="table">
//                 <thead>
//                   <tr>
//                     <th>S.No</th>
//                     <th>Affiliate Name</th>
//                     <th>Email</th>
//                     <th>PG Company</th>
//                     <th>GST Number</th>
//                     <th>Billing Address</th>
//                     <th>Link</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {record.length > 0 ? (
//                     record.map(
//                       (
//                         {
//                           affiliateid,
//                           affiliatename,
//                           affiliateemail,
//                           affiliatepgs,
//                         },
//                         i
//                       ) => (
//                         <tr key={i}>
//                           <td>{i + 1}</td>
//                           <td>{affiliatename}</td>
//                           <td>{affiliateemail}</td>
//                           <td>
//                             {affiliatepgs.length > 0
//                               ? affiliatepgs[0].pgmaster.CompanyName
//                               : ""}
//                           </td>
//                           <td>
//                             {affiliatepgs.length > 0
//                               ? affiliatepgs[0].pgmaster.GSTNumber
//                               : ""}
//                           </td>
//                           <td>
//                             {affiliatepgs.length > 0
//                               ? affiliatepgs[0].pgmaster.BillingAddress
//                               : ""}
//                           </td>
//                           <td>
//                             <Button
//                               size="small"
//                               disabled={!roles.includes(996617)}
//                               onClick={() =>
//                                 handleLinkClick({
//                                   affiliateid,
//                                   affiliatename,
//                                   affiliateemail,
//                                   affiliatepgs,
//                                 })
//                               }
//                             >
//                               Link
//                             </Button>
//                           </td>
//                         </tr>
//                       )
//                     )
//                   ) : (
//                     <NotFound cols={12} />
//                   )}
//                 </tbody>
//               </Table>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* ModalBox Component */}
//       {showModal && (
//         <ModalBox
//         status={showModal}
//         onClose={handleCloseModal}
//         title="Link Payment Gateway"
//         size="sm"
//         description={
//           <div style={{ height: "300px", overflowY: "auto" }}>
//             {/* Display the affiliate's name */}
//             <Typography variant="h6" gutterBottom>
//               {selectedRecord?.affiliatename}
//             </Typography>

//             {/* Dropdown using react-select */}
//             <Select
//               options={pgOptions}
//               value={selectedCompany}
//               onChange={(option) => setSelectedCompany(option)}
//               placeholder="Select Company Name"
//               styles={{
//                 container: (provided) => ({
//                   ...provided,
//                   marginTop: '10px'
//                 }),
//               }}
//             />
//           </div>
//         }
//         customizedFooter={
//           <div
//             style={{
//               textAlign: "center",
//               display: "flex",
//               justifyContent: "center",
//             }}
//           >
//             {/* Link button */}
//             <Button onClick={() => console.log("Link button clicked")}>
//               Link
//             </Button>
//           </div>
//         }
//       />
//       )}
//     </React.Fragment>
//   );
// };

// export default AffiliatePG;


import ModalBox from "../../components/modal/ModalBox";
import NotFound from "components/not-found/NotFound";
import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { Button } from "components/Button/Button";
import useFullPageLoader from "hooks/useFullPageLoader";
import Select from "react-select";
import { Typography } from "@mui/material";
import { useAdminAuth } from "context/context";
import { getAffiliatePGList, getPGMasterList, linkPGCompany } from "services/PGController/PGController"; // Adjust the import path as needed
import { Table } from "react-bootstrap";

const AffiliatePG = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  const [record, setRecord] = useState([]);
  const [pgOptions, setPgOptions] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const { roles } = useAdminAuth();

  // Fetch PG Master list options
  const fetchPGMasterList = async () => {
    try {
      const response = await getPGMasterList();
      if (response.code === 200) {
        const options = response.data.pgmasterlist.map((pg) => ({
          value: pg.CompanyName,
          label: pg.CompanyName,
          id: pg.id,
        }));
        setPgOptions(options);
      } else {
        toast.error(response.message || "Failed to fetch PG master list");
      }
    } catch (error) {
      toast.error("An error occurred while fetching PG master list");
    }
  };

  // Fetch affiliate PG list and PG master list when the component mounts
  useEffect(() => {
    fetchAffiliatePGList();
    fetchPGMasterList();
  }, []);

  const fetchAffiliatePGList = async () => {
    try {
      showLoader();
      const response = await getAffiliatePGList();
      if (response.code === 200) {
        setRecord(response.data.affiliatepglist);
        setTotal(response.data.affiliatepglist.length);
      } else {
        toast.error(response.message || "Failed to fetch data");
      }
    } catch (error) {
      toast.error("An error occurred while fetching data");
    } finally {
      hideLoader();
    }
  };

  const handleLinkClick = (record) => {
    console.log("Record clicked:", record);
    setSelectedRecord(record);
    setSelectedCompany(
      pgOptions.find(option => option.value === record.affiliatepgs[0]?.pgmaster.CompanyName) || null
    );
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedRecord(null);
    setSelectedCompany(null);
  };

  const handleLinkButtonClick = async () => {
    if (!selectedRecord || !selectedCompany) {
      toast.error("Please select a company");
      return;
    }

    const data = {
      affiliateid: selectedRecord.affiliateid,
      id: selectedCompany.id, // Use `id` from selectedCompany
      companyname: selectedCompany.value, // Use `value` from selectedCompany
    };

    try {
      const response = await linkPGCompany(data);
      if (response.code === 200) {
        toast.success("Payment Gateway linked successfully");
        handleCloseModal();
        fetchAffiliatePGList(); // Refresh data if needed
      } else {
        toast.error(response.message || "Failed to link payment gateway");
      }
    } catch (error) {
      toast.error("An error occurred while linking payment gateway");
    }
  };

  return (
    <React.Fragment>
      <title>Shubhra Viraj | Billing Courses</title>
      <div className="">
        <div className="WrapperBox">
          <div className="Small-Wrapper">
            <div className="search_wrap">
              <div className="SearchBox"></div>
              <h4 className="Title">Affiliate PG List</h4>
            </div>
            <div className="TableList overflow-auto table-responsive">
              {loader}
              <Table bordered className="table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Affiliate Name</th>
                    <th>Email</th>
                    <th>PG Company</th>
                    <th>GST Number</th>
                    <th>Billing Address</th>
                    <th>Link</th>
                  </tr>
                </thead>
                <tbody>
                  {record.length > 0 ? (
                    record.map(
                      (
                        {
                          affiliateid,
                          affiliatename,
                          affiliateemail,
                          affiliatepgs,
                        },
                        i
                      ) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{affiliatename}</td>
                          <td>{affiliateemail}</td>
                          <td>
                            {affiliatepgs.length > 0
                              ? affiliatepgs[0].pgmaster.CompanyName
                              : ""}
                          </td>
                          <td>
                            {affiliatepgs.length > 0
                              ? affiliatepgs[0].pgmaster.GSTNumber
                              : ""}
                          </td>
                          <td>
                            {affiliatepgs.length > 0
                              ? affiliatepgs[0].pgmaster.BillingAddress
                              : ""}
                          </td>
                          <td>
                            <Button
                              size="small"
                              disabled={!roles.includes(996617)}
                              onClick={() =>
                                handleLinkClick({
                                  affiliateid,
                                  affiliatename,
                                  affiliateemail,
                                  affiliatepgs,
                                })
                              }
                            >
                              Link
                            </Button>
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <NotFound cols={12} />
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* ModalBox Component */}
      {showModal && (
        <ModalBox
          status={showModal}
          onClose={handleCloseModal}
          title="Link Payment Gateway"
          size="sm"
          description={
            <div style={{ height: "300px", overflowY: "auto" }}>
              {/* Display the affiliate's name */}
              <Typography variant="h6" gutterBottom>
                {selectedRecord?.affiliatename}
              </Typography>

              {/* Dropdown using react-select */}
              <Select
                options={pgOptions}
                value={selectedCompany}
                onChange={(option) => setSelectedCompany(option)}
                getOptionValue={(option) => option.id}
                placeholder="Select Company Name"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    marginTop: '10px'
                  }),
                }}
              />
            </div>
          }
          customizedFooter={
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {/* Link button */}
              <Button onClick={handleLinkButtonClick}>
                Link
              </Button>
            </div>
          }
        />
      )}
    </React.Fragment>
  );
};

export default AffiliatePG;
